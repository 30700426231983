import { Token } from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useBurnContext } from "./BurnContext";
import useBurnService from "../hooks/useBurnService";
import { FETCH_TOKEN_INTERVAL } from "../utils/constants";

interface TokenContextProps {
  tokens: Token[];
  isLoading: boolean;
  updateTokens: () => Promise<void>;
}

const initialState: TokenContextProps = {
  tokens: [],
  isLoading: false,
  updateTokens: async () => {},
};

const TokenContext = createContext<TokenContextProps>(initialState);

export function useBurnTokens() {
  return useContext(TokenContext);
}

export function BurnTokenContextProvider(props: { children: ReactNode }) {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { burnServices } = useBurnService();
  const { burnChain, burnAddr, token, onTokenChange, passedProps } =
    useBurnContext();

  const fetchTokenList = useCallback(async () => {
    return await Promise.all(
      burnServices.map((s) => s.fetchTokens(passedProps?.tokenIds, burnAddr)),
    ).then((res) => {
      return res.flat();
    });
  }, [
    passedProps?.tokenIds?.length,
    burnChain,
    burnAddr,
    burnServices[0]?.chain.chain_id,
  ]);

  useEffect(() => {
    if (!(tokens[0]?.chain_id === burnServices[0]?.chain.chain_id)) {
      setTokens([]);
      setIsLoading(true);
    }
    if (burnChain) {
      const tokenUpdate = () => {
        fetchTokenList().then((tokens) => {
          setTokens(tokens);
          if (token) {
            const _token = tokens.find((t) => t.token_id === token.token_id);
            onTokenChange(_token);
          } else if (passedProps?.tokenIds?.length && tokens[0]) {
            onTokenChange(tokens[0]);
          }
          setIsLoading(false);
        });
      };
      tokenUpdate();

      const tick = setInterval(tokenUpdate, FETCH_TOKEN_INTERVAL);
      return () => {
        tick && clearInterval(tick);
      };
    }
  }, [
    fetchTokenList,
    burnChain,
    passedProps?.tokenIds?.length,
    token?.token_id,
  ]);

  const updateTokens = useCallback(async () => {
    fetchTokenList().then((tokens) => {
      setTokens(tokens);
      if (token) {
        const _token = tokens.find((t) => t.token_id === token.token_id);
        if (_token) {
          onTokenChange(_token);
        }
      }
    });
  }, [fetchTokenList, token?.token_id, onTokenChange]);

  const contextValue = useMemo(() => {
    return {
      tokens,
      isLoading,
      updateTokens,
    };
  }, [tokens, isLoading, updateTokens]);

  return (
    <TokenContext.Provider value={contextValue}>
      {props.children}
    </TokenContext.Provider>
  );
}
