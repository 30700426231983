import { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Circle,
  Spinner,
  chakra,
  Text,
  Divider,
  Stack,
} from "@chakra-ui/react";
import { ChevronRight } from "lucide-react";
import { useTransferContext } from "../../context/TransferContext";
import ChainLogo from "../ChainLogo";
import { formatUnits, readableNumber } from "../../utils/format";
import { BridgeFee } from "@types";
import useTransferService from "@hooks/useTransferService";
import { getChainIdFromName } from "../../utils/chains";

const ChevronRightIcon = chakra(ChevronRight);

export default function ChainToChain({
  broadcasting,
  showAddr,
  isCompelted,
}: {
  broadcasting?: boolean;
  showAddr?: boolean;
  isCompelted: boolean;
}) {
  const { sourceChain, targetChain, sourceAddr, targetAddr, amount, token } =
    useTransferContext();
  const { sourceService } = useTransferService();
  const [bridgeFee, setBridgeFee] = useState<BridgeFee>();

  const [active, setActive] = useState(-1);

  useEffect(() => {
    if (broadcasting) {
      const listner = setInterval(() => {
        setActive((prev) => (prev + 1) % 4);
      }, 400);
      return () => clearInterval(listner);
    } else {
      setActive(-1);
    }
  }, [broadcasting]);

  useEffect(() => {
    const chainId = getChainIdFromName(targetChain)[0];
    if (sourceService && chainId) {
      sourceService
        .getBridgeFee(chainId, token)
        .then(setBridgeFee)
        .catch(console.error);
    }
  }, [sourceService?.chain.chain_id, targetChain, token?.token_id]);

  const data = [
    {
      key: "Sender",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
          color="#008cd5"
        >
          {sourceAddr}
        </Text>
      ),
    },
    {
      key: "Receiver",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
          color="#008cd5"
        >
          {targetAddr}
        </Text>
      ),
    },
    {
      key: "Bridge Fee",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
        >
          {bridgeFee
            ? `${readableNumber(
                formatUnits(bridgeFee.fee, bridgeFee.decimals),
                bridgeFee.decimals * 0.5,
              )} ${bridgeFee.symbol}`
            : "Loading..."}
        </Text>
      ),
    },
  ];

  // if (token && token.fee) {
  //   data.push({
  //     key: "Fee",
  //     value: (
  //       <HStack>
  //         <Text
  //           fontWeight={600}
  //           inlineSize={300}
  //           textAlign={{ base: "left", md: "right" }}
  //         >
  //           {formatUnits(token?.fee || 0n, token?.decimals || 0)} {token.symbol}
  //         </Text>
  //       </HStack>
  //     ),
  //   });
  // }

  return (
    <VStack w="100%">
      <HStack gap={6}>
        <VStack>
          <Circle
            pos="relative"
            size={24}
            borderWidth={isCompelted ? 4 : 0}
            borderColor="green.500"
          >
            <ChainLogo chain={sourceChain} size={100} />
            {broadcasting && !isCompelted && (
              <Spinner
                pos="absolute"
                w="100%"
                height="100%"
                color="blue.500"
                speed="1s"
                emptyColor="gray.200"
                thickness="4px"
              />
            )}
          </Circle>
        </VStack>
        <VStack gap={2}>
          <HStack gap={0}>
            {new Array(4).fill(0).map((_, i) => {
              return (
                <ChevronRightIcon
                  key={i}
                  size={24}
                  strokeWidth={2}
                  color={isCompelted || active === i ? "blue.500" : "gray.200"}
                />
              );
            })}
          </HStack>
        </VStack>
        <VStack>
          <Circle pos="relative" size={24}>
            <ChainLogo chain={targetChain} size={100} />
          </Circle>
        </VStack>
      </HStack>
      <HStack gap={2} alignItems="flex-end">
        <Text fontWeight={600} fontSize={32} lineHeight={1}>
          {readableNumber(amount)} {token?.symbol}
        </Text>
      </HStack>

      <Divider w="100%" my={2} />

      <VStack w="100%" alignItems="flex-start">
        {showAddr &&
          data.map((d) => {
            return (
              <Stack
                flexDir={{ base: "column", md: "row" }}
                key={d.key}
                w="100%"
                gap={0}
                justifyContent="space-between"
              >
                <Text w={20} color="#999">
                  {d.key}
                </Text>
                <Text fontWeight={600}>{d.value}</Text>
              </Stack>
            );
          })}
      </VStack>
    </VStack>
  );
}
