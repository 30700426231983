import { IDL } from '@dfinity/candid'
import {
  idlFactory as ICPCustomsFactory,
  _SERVICE as ICPCustomsService,
} from './ICPCustoms.did'
import {
  idlFactory as ProxyFactory,
  _SERVICE as ProxyService,
} from './Proxy.did'
import {
  idlFactory as CosmRouteFactory,
  _SERVICE as CosmRouteService,
} from './CosmRoute.did'
import { Actor, HttpAgent } from '@dfinity/agent'
import {
  ICP_CUSTOMS_CANISTER_ID,
  PROXY_CANISTER_ID,
  COSM_ROUTE_CANISTER_ID,
} from '../utils/constants'

export const createActor = async <T>(
  canisterId: string,
  interfaceFactory: IDL.InterfaceFactory
) => {
  const agent = await HttpAgent.create({
    host: 'https://icp0.io/',
  })
  return Actor.createActor<T>(interfaceFactory, {
    canisterId,
    agent,
  })
}

export const getICPCustomsService = async () => {
  return createActor<ICPCustomsService>(
    ICP_CUSTOMS_CANISTER_ID,
    ICPCustomsFactory
  )
}

export const getProxyService = async () => {
  return createActor<ProxyService>(PROXY_CANISTER_ID, ProxyFactory)
}

export const getCosmRouteService = async () => {
  return createActor<CosmRouteService>(COSM_ROUTE_CANISTER_ID, CosmRouteFactory)
}
