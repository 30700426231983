import "./global.css";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import { Stack } from "@chakra-ui/react";
import LIGHT_BG from "./assets/light-bg.png";


export default function Layout() {
  return (
    <Stack 
      bg="#000433" className="outfit" 
      color="white" 
      style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}
      bgImage={`url(${LIGHT_BG})`}
      bgSize="contain"
      bgRepeat="no-repeat"
      bgPos="0px 200px"
    >
      <Header />
      <div style={{flexGrow: 1,display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Outlet />
      </div>
      <Footer />
    </Stack>
  );
}
