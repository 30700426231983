import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  Text,
  useDisclosure,
  VStack,
  Link,
} from "@chakra-ui/react";
import { TicketAction, TicketItem } from "../types";
import { ArrowRight, ArrowUpRight, NotebookTextIcon } from "lucide-react";
import { Bitcoin, Osmosis } from "./icons";
import { useEffect, useState } from "react";
import { useWidgetContext } from "src/context/WidgetContext";
import { gql } from "graphql-request";
import { fetchGraph } from "src/utils/graphFetch";
import { formatUnits } from "src/utils/format";
import { BTC_DECIMALS } from "src/utils/constants";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const isRedeemTicket = (action: TicketAction) =>
  action === TicketAction.Redeem ||
  action === TicketAction.RedeemIcpChainKeyAssets;

export default function History() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { osmAddr } = useWidgetContext();
  const [tickets, setTickets] = useState<TicketItem[]>([]);

  useEffect(() => {
    if (osmAddr) {
      fetchTicketsByAddress([osmAddr]).then(async (result) => {
        try {
          setTickets(result.tickets);
        } catch (error) {}
      });
    }
  }, [osmAddr]);

  return (
    <>
      <HStack w="100%" justifyContent="flex-end" alignItems="center">
        <HStack onClick={onOpen} cursor="pointer">
          <NotebookTextIcon size={16} />
          <Text color="blue.100" fontWeight={600}>
            History
          </Text>
        </HStack>
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>History</ModalHeader>
          <ModalCloseButton />
          <ModalBody w="100%" px={0}>
            <VStack w="100%" gap={0} h={400} overflowY="scroll">
              {tickets.map((ticket, i) => {
                const fromAddrComp = !isRedeemTicket(ticket.action) ? (
                  <Bitcoin size={24} />
                ) : (
                  <Osmosis size={24} />
                );
                const toAddrComp = isRedeemTicket(ticket.action) ? (
                  <Bitcoin size={24} />
                ) : (
                  <Osmosis size={24} />
                );
                return (
                  <VStack
                    key={i}
                    w="100%"
                    gap={1}
                    py={2}
                    px={6}
                    borderBottomWidth={1}
                    borderColor="gray.600"
                  >
                    <HStack w="100%" justifyContent="space-between">
                      <Text color="gray.500" fontSize={14}>
                        {formatTicketTime(ticket.ticket_time)}
                      </Text>
                      <HStack>
                        {fromAddrComp}
                        <ArrowRight size={20} strokeWidth={1} />
                        {toAddrComp}
                      </HStack>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between">
                      <Text>Ticket ID</Text>
                      <Link
                        href={`https://explorer.omnity.network/ticket/${ticket.ticket_id}`}
                        target="_blank"
                      >
                        <HStack>
                          <Text
                            color="blue.400"
                            maxW={200}
                            isTruncated
                            fontFamily="monospace"
                          >
                            {ticket.ticket_id}
                          </Text>
                          <ArrowUpRight size={16} />
                        </HStack>
                      </Link>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between">
                      <Text>From</Text>
                      <Text
                        color="blue.400"
                        maxW={200}
                        isTruncated
                        fontFamily="monospace"
                      >
                        {ticket.sender}
                      </Text>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between">
                      <Text>To</Text>
                      <Text
                        color="blue.400"
                        maxW={200}
                        isTruncated
                        fontFamily="monospace"
                      >
                        {ticket.receiver}
                      </Text>
                    </HStack>
                    <HStack w="100%" justifyContent="space-between">
                      <Text>Amount</Text>
                      <Text>
                        {formatUnits(BigInt(ticket.amount), BTC_DECIMALS)} BTC
                      </Text>
                    </HStack>
                  </VStack>
                );
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

async function fetchTicketsByAddress(
  addrs: string[],
): Promise<{ tickets: TicketItem[]; total: number }> {
  try {
    const addrFilter = addrs.map((addr) => {
      return `{receiver:{_iregex:"${addr}"}}, {sender:{_iregex:"${addr}"}}`;
    });
    const doc = gql`
      {
        ticket_aggregate(order_by: {ticket_time: desc},
          where: {
            _or: [${addrFilter.join(",")}]
        }) {
          aggregate {
            count
          }
        }
        ticket(
          order_by: {ticket_time: desc},
          where: {
            _or: [${addrFilter.join(",")}]
          }
          limit: 30
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      total: data.ticket_aggregate.aggregate.count,
      tickets: data.ticket,
    };
  } catch (error) {
    return {
      total: 0,
      tickets: [],
    };
  }
}

function formatTicketTime(time: number) {
  const _time = Math.floor(time / 1000000);
  if (_time < 0) {
    return "-";
  }
  const _now = Date.now();
  if (_now - _time > 1000 * 60 * 60 * 24 * 15) {
    return dayjs(_time).format("MM-DD HH:mm:ss");
  }
  return dayjs(_time).fromNow();
}
