import { Circle } from "@chakra-ui/react";
import { Check } from "lucide-react";

export default function ItemSelected({ size }: { size: number }) {
  return (
    <Circle bg="pink.400">
      <Check size={size} strokeWidth={3} color="white" />
    </Circle>
  );
}
