import {
  ChainName,
  ChainState,
  OmnityWidgetProps,
  SubmitRequire,
  Token,
} from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHubContext } from "./OmnityHubContext";
import { getChainAddr, isEvmChain } from "../utils/chains";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

interface MintContextProps {
  mintChain: ChainName;
  mintAddr?: string;
  token?: Token;
  amount: bigint;
  receiverAddr: string;
  useConnectWalletForReceiver: boolean;
  passedProps?: OmnityWidgetProps;
  submitRequest?: SubmitRequire;
  onTokenChange: (token?: Token) => void;
  onMintChainChange: (chain?: ChainName) => void;
  onToggleConnectWalletForReceiver: () => void;
  onReceiverAddrChange: (addr: string) => void;
  onAmountChange: (amount: bigint) => void;
}

const initialState: MintContextProps = {
  mintChain: ChainName.ICP,
  mintAddr: "",
  receiverAddr: "",
  amount: 0n,
  useConnectWalletForReceiver: true,
  token: undefined,
  submitRequest: undefined,
  onTokenChange: () => {},
  onMintChainChange: () => {},
  onToggleConnectWalletForReceiver: () => {},
  onReceiverAddrChange: () => {},
  onAmountChange: () => {},
};

const MintContext = createContext<MintContextProps>(initialState);

export function useMintContext() {
  return useContext(MintContext);
}

export function MintContextProvider(
  props: OmnityWidgetProps & {
    children: ReactNode;
  },
) {
  const { tokenIds, sourceChain } = props;

  const passedProps = {
    tokenIds,
  };
  const [mintChain, setMintChain] = useState<ChainName>(
    sourceChain === ChainName.Bitcoin
      ? initialState.mintChain
      : sourceChain ?? initialState.mintChain,
  );
  const [selectedToken, setSelectedToken] = useState<Token>();
  const [receiverAddr, setReceiverAddr] = useState(initialState.receiverAddr);
  const [useConnectWalletForReceiver, setUseConnectWalletForReceiver] =
    useState(initialState.useConnectWalletForReceiver);
  const [amount, setAmount] = useState(initialState.amount);

  const { chains, addresses } = useHubContext();
  const { chainId: evmChainId } = useEVMWalletKit();

  const mintAddr = getChainAddr(addresses, mintChain);

  useEffect(() => {
    if (sourceChain) {
      const chain = chains.find(
        (c) => c.chain_name.toLowerCase() === sourceChain?.toLowerCase(),
      );

      if (tokenIds?.[0] && chain) {
        const token = chain.token_list?.find((t) => t.token_id === tokenIds[0]);
        token && setSelectedToken(token);
      }
    }
  }, [sourceChain, tokenIds?.[0], chains.length]);

  useEffect(() => {
    if (mintChain) {
      const chain = chains.find(
        (c) => c.chain_name.toLowerCase() === mintChain.toLowerCase(),
      );
      if (
        chain &&
        !chain.token_list?.find((t) => t.token_id === selectedToken?.token_id)
      ) {
        setSelectedToken(undefined);
        setAmount(0n);
      }
    }
  }, [mintChain, selectedToken?.token_id, chains.length]);

  const submitRequest = useMemo(() => {
    if (!mintChain) {
      return SubmitRequire.Select_Mint_Chain;
    } else {
      if (!mintAddr) {
        return SubmitRequire.Connect_Mint_Wallet;
      }
      if (isEvmChain(mintChain)) {
        const evmChain = chains.find(
          (c) => c.chain_name.toLowerCase() === mintChain.toLowerCase(),
        );
        if (evmChain && evmChain.evm_chain?.id !== evmChainId) {
          return SubmitRequire.Wrong_Network;
        }
      }
    }

    const mintChainActive = chains.find(
      (chain) =>
        chain.chain_name.toLowerCase() === mintChain.toLowerCase() &&
        chain.chain_state === ChainState.Active,
    );
    if (!mintChainActive) {
      return SubmitRequire.Chain_Not_Active;
    }

    if (!selectedToken) return SubmitRequire.Select_Token;

    return SubmitRequire.Confirm;
  }, [selectedToken, mintChain, mintAddr, evmChainId, chains.length]);

  const onMintChainChange = useCallback((chainName?: ChainName) => {
    if (chainName) {
      setMintChain(chainName);
      setReceiverAddr("");
    }
  }, []);

  const connectedTargetAddr = mintAddr;

  useEffect(() => {
    if (useConnectWalletForReceiver && connectedTargetAddr) {
      setReceiverAddr(connectedTargetAddr);
    } else if (!useConnectWalletForReceiver) {
      setReceiverAddr("");
    }
  }, [connectedTargetAddr, useConnectWalletForReceiver, mintChain]);

  const contextValue = useMemo(() => {
    const value: MintContextProps = {
      mintChain,
      mintAddr,
      receiverAddr,
      amount,
      token: selectedToken,
      submitRequest,
      onTokenChange: setSelectedToken,
      onMintChainChange,
      passedProps,
      useConnectWalletForReceiver,
      onToggleConnectWalletForReceiver: () => {
        setUseConnectWalletForReceiver((prev) => !prev);
      },
      onReceiverAddrChange: setReceiverAddr,
      onAmountChange: setAmount,
    };
    return value;
  }, [
    mintChain,
    mintAddr,
    selectedToken,
    submitRequest,
    receiverAddr,
    useConnectWalletForReceiver,
    amount,
  ]);

  return (
    <MintContext.Provider value={contextValue}>
      {props.children}
    </MintContext.Provider>
  );
}
