import { EventType, WalletType } from "../../types";
import * as PubSub from "pubsub-js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Text,
  VStack,
  useToast,
  useColorModeValue,
  ModalCloseButton,
} from "@chakra-ui/react";
import { KeplrIcon } from "./icons";
import { CHAIN_INFO } from "../../constants";

export default function OsmosisWalletKitModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const toast = useToast();
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");

  return (
    <Modal isOpen={open} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={8} color={textColor}>
        <ModalHeader color={textColor}>Connect ICP Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <HStack
            gap={4}
            px={4}
            py={2}
            borderRadius={8}
            cursor="pointer"
            onClick={async () => {
              try {
                if (!window.keplr) {
                  alert("Please install keplr extension");
                } else {
                  await window.keplr.experimentalSuggestChain(CHAIN_INFO);
                  await window.keplr.enable(CHAIN_INFO.chainId);
                  const offlineSigner = window.keplr.getOfflineSigner(
                    CHAIN_INFO.chainId,
                  );
                  const accounts = await offlineSigner.getAccounts();

                  if (accounts[0]) {
                    PubSub.publish(EventType.ON_CONNECT, {
                      type: WalletType.Keplr,
                      address: accounts[0].address,
                    });
                  } else {
                    throw new Error("There is no available key");
                  }
                }
                onClose();
              } catch (error) {
                toast({
                  description: (error as Error).message,
                  status: "error",
                  duration: 3000,
                });
              }
            }}
            _hover={{
              bg: boxWrapperBg,
            }}
          >
            <KeplrIcon size={56} />
            <VStack alignItems="flex-start" gap={0}>
              <Text fontWeight={600} fontSize={24} color={textColor}>
                Keplr Wallet
              </Text>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
