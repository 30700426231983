import { ModalCloseButton, useColorModeValue } from "@chakra-ui/react";

export default function CloseButtonForModal() {
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.900");
  return (
    <ModalCloseButton
      rounded="full"
      cursor="pointer"
      size="sm"
      top={4}
      right={4}
      color={textColor}
      bg={boxWrapperBg}
      _hover={{
        transform: "scale(1.2)",
        bg: boxWrapperHoverBg,
      }}
      _active={{
        transform: "scale(1)",
      }}
    />
  );
}
