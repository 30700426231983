import { EventType } from "../../types";
import * as PubSub from "pubsub-js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Text,
  useColorModeValue,
  useToast,
  useDisclosure,
  VStack,
  Badge,
} from "@chakra-ui/react";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import { useEffect } from "react";
import {
  OKX,
  useLaserEyes,
  UNISAT,
  LEATHER,
  WalletIcon,
} from "@omnisat/lasereyes";
import { BTC_WALLETS } from "@wallet-kits/btc-wallet-kit/constants";

export default function BTCWalletKitModal() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");
  const toast = useToast();

  useEffect(() => {
    const showListener = PubSub?.subscribe(EventType.ON_SHOW_MODAL, onOpen);
    const hideListener = PubSub?.subscribe(EventType.ON_HIDE_MODAL, onClose);
    return () => {
      PubSub?.unsubscribe(showListener);
      PubSub?.unsubscribe(hideListener);
    };
  }, []);

  const { connect, hasOkx, hasUnisat, hasLeather } = useLaserEyes();

  const walletInstalled = {
    [OKX]: hasOkx,
    [UNISAT]: hasUnisat,
    [LEATHER]: hasLeather,
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius={8}
        margin={{ base: 0 }}
        alignSelf={{ base: "flex-end", md: "center" }}
      >
        <ModalHeader color={textColor}>Connect Bitcoin Wallet</ModalHeader>
        <CloseButtonForModal />
        <ModalBody pb={6} color={textColor}>
          {BTC_WALLETS.map((wallet) => {
            return (
              <HStack
                key={wallet.type}
                gap={4}
                px={4}
                py={2}
                borderRadius={8}
                cursor="pointer"
                onClick={async () => {
                  try {
                    if (!walletInstalled[wallet.type]) {
                      throw new Error("Wallet not installed");
                    }
                    await connect(wallet.type as any);
                    onClose();
                  } catch (error) {
                    toast({
                      description: (error as Error).message,
                      status: "error",
                      duration: 5000,
                    });
                  }
                }}
                _hover={{
                  bg: boxWrapperBg,
                }}
              >
                <WalletIcon walletName={wallet.type} size={48} />
                <VStack gap={0} alignItems="flex-start">
                  <Text fontWeight={600} fontSize={24} color={textColor}>
                    {wallet.name}
                  </Text>
                  {walletInstalled[wallet.type] && (
                    <Badge size="sm" fontSize={8}>
                      INSTALLED
                    </Badge>
                  )}
                </VStack>
              </HStack>
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
