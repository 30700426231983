import { useBTCWalletKit } from "../../wallet-kits/btc-wallet-kit";
import { Button } from "@chakra-ui/react";

export default function BTCConnectButton({
  forSubmit,
}: {
  forSubmit: boolean;
}) {
  const { address: btcAddress, onShowModal } = useBTCWalletKit();
  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={() => onShowModal()}
      disabled={!!btcAddress}
    >
      Connect Bitcoin Wallet
    </Button>
  );
}
