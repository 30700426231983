import { ReactNode, useCallback } from "react";
import PubSub from "pubsub-js";
import { EventType } from "../types";
import { BTCWalletKitModal } from "..";
import { LaserEyesProvider, MAINNET, useLaserEyes } from "@omnisat/lasereyes";
import * as bitcoin from "bitcoinjs-lib";

interface TransferRunesParams {
  wallet_address: string;
  receiver_address: string;
  rune_name: string;
  fee_rate: number;
  amount: string;
}

interface TransferBrc20Params {
  sender: string;
  receiver: string;
  tick: string;
  fee_rate: number;
  amount: string;
  deposit_addr: string;
  deposit_public: string;
}

interface BTCWalletKitProviderProps {
  address: string;
  onShowModal: () => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  transferRunes: (params: TransferRunesParams) => Promise<string>;
  transferBrc20: (params: TransferBrc20Params) => Promise<string>;
}

export function BTCWalletProvider({ children }: { children: ReactNode }) {
  return (
    <LaserEyesProvider config={{ network: MAINNET }}>
      {children}
      <BTCWalletKitModal />
    </LaserEyesProvider>
  );
}

async function broadcastTx(content: string, isPsbt: boolean = true) {
  let rawTxHex = content;
  if (isPsbt) {
    const psbt = bitcoin.Psbt.fromBase64(content);
    const transaction = psbt.extractTransaction();
    rawTxHex = transaction.toHex();
  }

  const result = await fetch(`https://mempool.space/api/tx`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      accept: "application/json",
    },
    body: rawTxHex,
  }).then((res) => res.text());

  return result;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useBTCWalletKit() {
  const { address, disconnect, sendBTC, balance } = useLaserEyes();

  const onShowModal = useCallback(() => {
    PubSub.publish(EventType.ON_SHOW_MODAL, {});
  }, []);

  const onHideModal = useCallback(() => {
    PubSub.publish(EventType.ON_HIDE_MODAL, {});
  }, []);

  return {
    address,
    onShowModal,
    onHideModal,
    onDisconnect: disconnect,
    send: sendBTC,
    balance: BigInt(balance ?? 0),
  };
}
