import { ChakraProvider } from "@chakra-ui/react";
import { ChainID, ChainName, OmnityWidgetProps, TabAction } from "./types";
import { BTCWalletProvider } from "./wallet-kits/btc-wallet-kit";
import { ICPWalletProvider } from "./wallet-kits/icp-wallet-kit";
import { HubProvider } from "./context/OmnityHubContext";
import EthereumWaklletKit from "./wallet-kits/evm-wallet-kit";
import { SOLWalletProvider } from "@wallet-kits/sol-wallet-kit";
import posthog from "posthog-js";
import { extendDefaultTheme } from "./themes/bitfinity/theme";
import Bitfinity from "./Bitfinity";

posthog.init("phc_UtXfkZaaPLBCmniOY2tuSDGj8bCkTiIuGp0ZnAuFBUO", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
});

export default function BitfinityWidget({
  customs = ChainID.sICP,
  sourceChain = ChainName.ICP,
  targetChain = ChainName.Bitfinity,
  tokenIds,
  network = "mainnet",
  colorMode = "system",
  width = 540,
  reversible = false,
  isPeerToPeer = false,
  tab = TabAction.Transfer,
  onTabChange,
}: OmnityWidgetProps) {
  const _tab = tab ?? TabAction.Transfer;

  const config = {
    initialColorMode: colorMode,
    useSystemColorMode: colorMode === "system" ? true : false,
  };

  const theme = extendDefaultTheme({ config });

  return (
    <ChakraProvider theme={theme}>
      <BTCWalletProvider>
        <ICPWalletProvider>
          <EthereumWaklletKit>
            <SOLWalletProvider>
              <HubProvider
                customs={customs}
                network={network}
                tab={_tab}
                onTabChange={onTabChange}
              >
                <Bitfinity
                  customs={customs}
                  width={width}
                  sourceChain={sourceChain}
                  targetChain={targetChain}
                  tokenIds={tokenIds}
                  reversible={reversible}
                  isPeerToPeer={isPeerToPeer}
                  tab={_tab}
                  onTabChange={onTabChange}
                />
              </HubProvider>
            </SOLWalletProvider>
          </EthereumWaklletKit>
        </ICPWalletProvider>
      </BTCWalletProvider>
    </ChakraProvider>
  );
}
