import { ArrowRightLeft } from "lucide-react";
import { Center, chakra } from "@chakra-ui/react";

const ArrowRightLeftIcon = chakra(ArrowRightLeft);
export default function ConvertButton({
  reversible,
  onRevert,
}: {
  reversible?: boolean;
  onRevert?: () => void;
}) {
  let _reversible = reversible;
  return (
    <Center
      h={0}
      zIndex={10}
      pos="relative"
      top={{ base: 0, md: 4 }}
      mt={{ base: 2, md: 0 }}
      cursor={_reversible ? "pointer" : "not-allowed"}
      onClick={_reversible ? onRevert : undefined}
    >
      <ArrowRightLeftIcon
        size={20}
        _hover={{
          transform: `scale(1.1)`,
          strokeWidth: 3,
        }}
      />
    </Center>
  );
}
