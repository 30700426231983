import { chakra, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { BridgeDirection } from "../types";
import { ArrowRightLeft } from "lucide-react";
import { Bitcoin, Osmosis } from "./icons";
import { BTCAddress } from "src/wallet-kits/btc-wallet-kit";
import { OsmosisAddress } from "src/wallet-kits/osmosis-wallet-kit";

const ArrowRightLeftIcon = chakra(ArrowRightLeft);

export default function Wallets({
  direction,
  onRevert,
}: {
  direction: BridgeDirection;
  onRevert?: () => void;
}) {
  const fromWalletComp =
    direction === BridgeDirection.Transfer ? (
      <BTCAddress />
    ) : (
      <OsmosisAddress />
    );
  const toWalletComp =
    direction !== BridgeDirection.Transfer ? (
      <BTCAddress />
    ) : (
      <OsmosisAddress />
    );

  const fromChainComp =
    direction === BridgeDirection.Transfer ? (
      <Bitcoin size={30} />
    ) : (
      <Osmosis size={30} />
    );

  const toChainComp =
    direction !== BridgeDirection.Transfer ? (
      <Bitcoin size={30} />
    ) : (
      <Osmosis size={30} />
    );

  return (
    <Stack
      flexDir={{ base: "column", md: "row" }}
      w="100%"
      gap={0}
      justifyContent="space-between"
      alignItems={{ base: "center" }}
    >
      <VStack w={{ base: "100%", md: "44%" }} alignItems="flex-start" gap={1}>
        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight={600}>From</Text>
          {fromWalletComp}
        </HStack>
        <HStack
          w="100%"
          bg="gray.700"
          alignItems="flex-start"
          borderRadius={4}
          px={2}
          py={2}
        >
          {fromChainComp}
          <Text fontSize={20} fontWeight={600}>
            {direction === BridgeDirection.Transfer ? "Bitcoin" : "Osmosis"}
          </Text>
        </HStack>
      </VStack>

      <ArrowRightLeftIcon
        color="white"
        size={20}
        cursor="pointer"
        mt={8}
        _hover={{
          transform: `scale(1.1)`,
          strokeWidth: 3,
        }}
        onClick={onRevert}
      />

      <VStack w={{ base: "100%", md: "44%" }} alignItems="flex-start" gap={1}>
        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight={600}>To</Text>
          {toWalletComp}
        </HStack>
        <HStack
          w="100%"
          bg="gray.700"
          alignItems="flex-start"
          borderRadius={4}
          px={2}
          py={2}
        >
          {toChainComp}
          <Text fontSize={20} fontWeight={600}>
            {direction === BridgeDirection.Redeem ? "Bitcoin" : "Osmosis"}
          </Text>
        </HStack>
      </VStack>
    </Stack>
  );
}
