import {
  OKX,
  OkxLogo,
  UNISAT,
  UnisatLogo,
  LEATHER,
  LeatherLogo,
} from "@omnisat/lasereyes";

type BTC_WALLET = typeof OKX | typeof UNISAT | typeof LEATHER;

export const BTC_WALLETS: { type: BTC_WALLET; logo: any; name: string }[] = [
  {
    type: OKX,
    logo: OkxLogo,
    name: "OKX",
  },
  {
    type: UNISAT,
    logo: UnisatLogo,
    name: "UniSat",
  },
  {
    type: LEATHER,
    logo: LeatherLogo,
    name: "Leather",
  },
];
