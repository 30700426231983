import useMintService from "@hooks/useMintService";
import { useMintContext } from "../../context/MintContext";
import BaseSubmit from "./BaseSubmit";

export default function SubmitMint() {
  const { mintChain, submitRequest } = useMintContext();
  const { executeService } = useMintService();

  return (
    <BaseSubmit
      chainName={mintChain}
      submitRequest={submitRequest}
      service={executeService}
    />
  );
}
