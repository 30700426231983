export function Osmosis({ size = 24 }: { size?: number }) {
  const random = Math.random();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 200 200"
      width={size}
      height={size}
    >
      <path
        fill="#1D1141"
        d="M720 180C720 80.655 558.689 0 360 0S0 80.655 0 180s161.311 180 360 180 360-80.655 360-180z"
        transform="scale(.27778 .55556)"
      ></path>
      <g transform="translate(-79.273 -81.202)">
        <path
          fill="#5E12A0"
          fillRule="nonzero"
          d="M238.7 136.3c-1.4-5.3-5.9-10.6-14-16.5-6.5-4.7-13.4-7.4-18.9-7.4-1.1 0-2.1.1-3.1.3-2.5.5-4.7 2.3-6.1 5-1.7 3.2-2.1 7.5-1 10.1.4.8.9 1.8 1.5 2.7-5.3 3.2-8.3 4.1-8.7 4.2 13.8 4.6 25.3 14.2 32.5 26.7l.1-1.2c.3-3.3 1.3-7.1 2.7-11 1.4.4 2.8.6 4.2.6 3.7 0 6.9-1.5 8.9-4.2s2.8-6.3 1.9-9.3z"
        ></path>
        <path
          fill={`url(#_Radial1${random})`}
          fillRule="nonzero"
          d="M224.3 146.3c9.1 2.5 12.8-4.5 11.6-9.2-1.3-4.7-5.5-9.5-12.9-14.9s-14.9-7.6-19.7-6.6c-4.8 1-6.2 8.3-5 11.1.5 1.1 1.6 2.7 3.1 4.5-1.9 1.3-3.7 2.3-5.2 3.2 9.2 4.1 17.2 10.5 23.2 18.5.7-2.7 1.7-5.1 2.6-7.2.7.1 1.5.3 2.3.6z"
        ></path>
        <circle
          cx="172.2"
          cy="188.4"
          r="55.9"
          fill={`url(#_Radial2${random})`}
        ></circle>
        <path
          fill="#A98698"
          fillOpacity="0.6"
          fillRule="nonzero"
          d="M231.3 132.5c-7.6-8-14-10.1-21.7-11.8-6-1.4-4.4-4.8 2.9-4.1-3.5-1.2-6.8-1.5-9.2-1-4.8 1-6.2 8.3-5 11.1.5 1.1 1.6 2.7 3.1 4.5-2.7 1.8-5 3.1-6.9 4.1.9.4 2 .9 3.3 1.6 3.4 1.8 7.1 4.8 7.1 4.8-5.6-4.8-4.4-7 3.3-12.4 2.4-1.7 6.8-1.5 10.9.6 4.1 2.1 8.9 7.4 8.9 7.4l-4.6 8.8.9.3c2.9.8 5.2.6 7-.1 2.1-1.3 7.6-5.7 0-13.8z"
        ></path>
        <path
          fill="#5E12A0"
          fillRule="nonzero"
          d="M209.5 125.5c2 .8 4.6 2.2 7.8 4.3 3.8 2.5 7.1 5.3 9.2 7.5-3.5 4.6-5.8 10.9-7.2 15.3.7 1 1.5 2 2.2 3 .7-2.5 1.9-6.2 3.5-9.9.4.1.9.1 1.4.1 1.2 0 2.6-.2 3.7-1.1.8-.6 1.7-1.7 1.6-3.7 0-1.9-1.5-4.3-4.6-7.2-2.2-2.1-5.2-4.4-8.2-6.5-8.6-5.7-14.6-7.3-17.3-4.6-1.8 1.8-1.6 4-1 5.6-3.2 2.1-5.9 3.6-7.7 4.6 1.2.4 2.3.9 3.5 1.4 3.2-1.7 7.8-4.6 13.1-8.8zm18.9 14.3c.3.5.4 1 .4 1.3 0 .9-.3 1.2-.5 1.4-.4.3-1.2.5-1.9.5.6-1.2 1.3-2.2 2-3.2zm-24.7-14.9c.3-.3 1.1-.5 2.4-.3-1 .8-2 1.5-3 2.2-.1-.7 0-1.4.6-1.9z"
        ></path>
        <path
          fill="#5E12A0"
          fillRule="nonzero"
          d="M172.2 129.2c-32.7 0-59.2 26.5-59.2 59.2s26.5 59.2 59.2 59.2 59.2-26.5 59.2-59.2-26.6-59.2-59.2-59.2zm0 115.1c-30.9 0-55.9-25-55.9-55.9s25-55.9 55.9-55.9 55.9 25 55.9 55.9-25.1 55.9-55.9 55.9z"
        ></path>
        <circle
          cx="172.2"
          cy="188.4"
          r="55.9"
          fill={`url(#_Linear3${random})`}
        ></circle>
        <path
          fill="#A98698"
          fillOpacity="0.6"
          fillRule="nonzero"
          d="M171.8 237.5c-30.5-4.9-51.2-33.6-46.2-64.1 2.2-13.5 9-25 18.6-33.3-14 8.1-24.4 22.2-27.2 39.4-4.9 30.5 15.8 59.2 46.2 64.1 17 2.8 33.4-2.5 45.5-12.9-10.7 6.2-23.7 8.9-36.9 6.8z"
        ></path>
        <path
          fill={`url(#_Linear4${random})`}
          fillRule="nonzero"
          d="M181.1 133.3c-14.1-2.3-27.8.9-39 8l-.4.4c4.3-2.6 10.6-4.9 10.6-4.9-16.2 9.4-21.2 20.1-21.2 20.1 6.3-12.2 24.8-20.8 39.3-21.4 14.5-.6 24 3.7 35.6 13 11.6 9.4 18.6 28.6 17.9 43.8-.6 15.2-8.6 27.5-8.6 27.5 5.5-7.1 8.8-12.3 10.9-17.6.4-1.6.8-3.2 1-4.9 5-30.4-15.6-59.1-46.1-64z"
        ></path>
        <path
          fill={`url(#_Linear5${random})`}
          fillRule="nonzero"
          d="M225.3 187.9c0 29.3-23.8 53.1-53.1 53.1S119 217.2 119 187.9h106.3z"
        ></path>
        <path
          fill={`url(#_Linear6${random})`}
          fillRule="nonzero"
          d="M221.1 187.9c0 28.6-22.6 52-51 53.1h2.1c29.3 0 53.1-23.8 53.1-53.1h-4.2z"
          opacity="0.6"
        ></path>
        <path
          fill={`url(#_Linear7${random})`}
          fillRule="nonzero"
          d="M126.5 187.9H119c0 29.3 23.8 53.1 53.1 53.1 1.3 0 2.5 0 3.7-.1-27.5-2-49.3-25-49.3-53z"
          opacity="0.7"
        ></path>
        <path
          fill={`url(#_Linear8${random})`}
          fillRule="nonzero"
          d="M225.2 188.4c0-6.1-10.6-9.6-24.7-10.8-10.2-.8-20.5.2-32.3 3.8-10.2 3-19.4 2.5-26.1 1.7-14.9-1.7-23.1-1.9-23.1 5.3 0 10.4 21.2 23.4 53 18.9 16.1-2.3 24.4-7 33.9-10.2 10.3-3.4 19.3-3.3 19.3-8.7z"
        ></path>
        <circle cx="189.6" cy="154.2" r="9" fill="#fff"></circle>
        <circle cx="204.3" cy="166.1" r="3.8" fill="#fff"></circle>
        <path
          fill={`url(#_Linear9${random})`}
          fillRule="nonzero"
          d="M217.9 147h-.2c-.6-.1-1-.7-.9-1.4.9-4.6 4.7-9 4.9-9.2.4-.5 1.2-.5 1.6-.1.5.4.5 1.2.1 1.6-.1.1-3.6 4.2-4.4 8.2-.1.6-.6.9-1.1.9z"
          opacity="0.6"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M205.8 112.4c5.6 0 12.5 2.7 18.9 7.4 8.1 5.9 12.6 11.1 14 16.5.8 3.1.1 6.6-1.9 9.2-2.1 2.7-5.2 4.2-8.9 4.2-1.3 0-2.7-.2-4.2-.6-.9 2.3-1.6 4.6-2.1 6.8 6.1 9.3 9.7 20.5 9.7 32.5 0 32.7-26.5 59.2-59.2 59.2S113 221.1 113 188.4s26.5-59.2 59.2-59.2c7.2 0 14.1 1.3 20.5 3.7 1.2-.6 2.7-1.4 4.4-2.4-.6-.9-1.2-1.8-1.5-2.7-1.1-2.7-.7-6.9 1-10.1 1.4-2.6 3.5-4.4 6.1-5 .9-.2 2-.3 3.1-.3m0-1.7c-1.2 0-2.4.1-3.5.4-3 .7-5.6 2.7-7.3 5.9-1.9 3.7-2.4 8.5-1.1 11.6.2.4.4.9.7 1.4-.7.4-1.4.8-2.1 1.1-6.6-2.3-13.4-3.5-20.4-3.5-8.2 0-16.2 1.6-23.7 4.8-7.3 3.1-13.8 7.5-19.4 13.1-5.6 5.6-10 12.1-13.1 19.4-3.2 7.5-4.8 15.5-4.8 23.7s1.6 16.2 4.8 23.7c3.1 7.3 7.5 13.8 13.1 19.4 5.6 5.6 12.1 10 19.4 13.1 7.5 3.2 15.5 4.8 23.7 4.8s16.2-1.6 23.7-4.8c7.3-3.1 13.8-7.5 19.4-13.1 5.6-5.6 10-12.1 13.1-19.4 3.2-7.5 4.8-15.5 4.8-23.7 0-11.7-3.3-23-9.6-32.8.3-1.5.8-3 1.3-4.5 1 .2 2.1.3 3.1.3 4.2 0 7.9-1.7 10.3-4.9 2.3-3 3.2-7.2 2.2-10.7-1.6-5.8-6.2-11.3-14.7-17.4-6.7-5.1-14-7.9-19.9-7.9z"
        ></path>
        <circle
          cx="168.5"
          cy="227.3"
          r="2.8"
          fill="#fff"
          fillOpacity="0.2"
        ></circle>
        <path
          fill={`url(#_Linear10${random})`}
          fillRule="nonzero"
          d="M166.9 229.1c-1.1-1.1-1.1-2.9 0-4 .2-.2.4-.3.6-.5-.4.1-.7.3-1 .6-1.1 1.1-1.1 2.9 0 4 .9.9 2.3 1.1 3.4.5-1 .4-2.2.2-3-.6z"
          opacity="0.4"
        ></path>
        <circle
          cx="169.5"
          cy="225.9"
          r="0.6"
          fill="#fff"
          fillOpacity="0.3"
        ></circle>
        <g>
          <circle
            cx="183.1"
            cy="225.9"
            r="2.8"
            fill="#fff"
            fillOpacity="0.2"
          ></circle>
          <path
            fill={`url(#_Linear11${random})`}
            fillRule="nonzero"
            d="M181.5 227.7c-1.1-1.1-1.1-2.9 0-4 .2-.2.4-.3.6-.5-.4.1-.7.3-1 .6-1.1 1.1-1.1 2.9 0 4 .9.9 2.3 1.1 3.4.5-1 .4-2.2.2-3-.6z"
            opacity="0.4"
          ></path>
          <circle
            cx="184.1"
            cy="224.5"
            r="0.6"
            fill="#fff"
            fillOpacity="0.3"
          ></circle>
        </g>
        <g>
          <circle
            cx="175.9"
            cy="233.1"
            r="2.1"
            fill="#fff"
            fillOpacity="0.2"
          ></circle>
          <path
            fill={`url(#_Linear12${random})`}
            fillRule="nonzero"
            d="M174.7 234.4c-.8-.8-.8-2.2 0-3 .1-.1.3-.2.4-.3-.3.1-.5.3-.7.5-.8.8-.8 2.2 0 3 .7.7 1.7.8 2.6.3-.8.3-1.7.1-2.3-.5z"
            opacity="0.4"
          ></path>
          <circle
            cx="176.6"
            cy="232"
            r="0.4"
            fill="#fff"
            fillOpacity="0.3"
          ></circle>
        </g>
        <g>
          <circle
            cx="188.8"
            cy="231.6"
            r="2.1"
            fill="#fff"
            fillOpacity="0.2"
          ></circle>
          <path
            fill={`url(#_Linear13${random})`}
            fillRule="nonzero"
            d="M187.6 232.9c-.8-.8-.8-2.2 0-3 .1-.1.3-.2.4-.3-.3.1-.5.3-.7.5-.8.8-.8 2.2 0 3 .7.7 1.7.8 2.6.3-.8.3-1.7.1-2.3-.5z"
            opacity="0.4"
          ></path>
          <circle
            cx="189.5"
            cy="230.5"
            r="0.4"
            fill="#fff"
            fillOpacity="0.3"
          ></circle>
        </g>
        <g>
          <circle
            cx="156.6"
            cy="224.5"
            r="4.6"
            fill="#fff"
            fillOpacity="0.2"
          ></circle>
          <path
            fill={`url(#_Linear14${random})`}
            fillRule="nonzero"
            d="M154 227.4c-1.8-1.8-1.8-4.7 0-6.5.3-.3.6-.5 1-.7-.6.2-1.1.6-1.6 1-1.8 1.8-1.8 4.7 0 6.5 1.5 1.5 3.8 1.8 5.6.7-1.7.7-3.7.4-5-1z"
            opacity="0.4"
          ></path>
          <circle
            cx="158.2"
            cy="222.1"
            r="1"
            fill="#fff"
            fillOpacity="0.3"
          ></circle>
        </g>
        <g>
          <circle
            cx="197.7"
            cy="213.2"
            r="4.6"
            fill="#fff"
            fillOpacity="0.2"
          ></circle>
          <path
            fill={`url(#_Linear15${random})`}
            fillRule="nonzero"
            d="M195 216.1c-1.8-1.8-1.8-4.7 0-6.5.3-.3.6-.5 1-.7-.6.2-1.1.6-1.6 1-1.8 1.8-1.8 4.7 0 6.5 1.5 1.5 3.8 1.8 5.6.7-1.7.7-3.6.4-5-1z"
            opacity="0.4"
          ></path>
          <circle
            cx="199.3"
            cy="210.8"
            r="1"
            fill="#fff"
            fillOpacity="0.3"
          ></circle>
        </g>
      </g>
      <defs>
        <radialGradient
          id={`_Radial1${random}`}
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(234.076 130.8) scale(44.6944)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FFEAFF" stopOpacity="0.6"></stop>
          <stop offset="0.68" stopColor="#A087C9"></stop>
          <stop offset="1" stopColor="#10002F"></stop>
        </radialGradient>
        <radialGradient
          id={`_Radial2${random}`}
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(209.21 149.861) scale(109.351)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FFEAFF" stopOpacity="0.6"></stop>
          <stop offset="0.68" stopColor="#A087C9"></stop>
          <stop offset="1" stopColor="#10002F"></stop>
        </radialGradient>
        <linearGradient
          id={`_Linear3${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(111.7467) rotate(-80.793 2.01 .231)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#81FFFF" stopOpacity="0.6"></stop>
          <stop offset="0.62" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear4${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="scale(-62.7331) rotate(-42.537 -4.767 3.134)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear5${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(119.045 214.419) scale(106.229)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0002E9"></stop>
          <stop offset="1" stopColor="#FF00C7"></stop>
          <stop offset="1" stopColor="#FF00C7"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear6${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="rotate(136.618 76.855 142.083) scale(79.5552)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear7${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(119.045 214.419) scale(56.8321)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#000292"></stop>
          <stop offset="1" stopColor="#7D00C7"></stop>
          <stop offset="1" stopColor="#7D00C7"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear8${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(119.116 192.811) scale(106.087)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#000292"></stop>
          <stop offset="1" stopColor="#BE00C7"></stop>
          <stop offset="1" stopColor="#BE00C7"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear9${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="rotate(121.607 73.757 130.391) scale(14.5481)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff"></stop>
          <stop offset="0.29" stopColor="#fff"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear10${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(165.672 227.386) scale(4.2309)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear11${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(180.262 225.967) scale(4.2309)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear12${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(173.787 233.124) scale(3.1763)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear13${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(186.644 231.62) scale(3.1763)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear14${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(152.004 224.617) scale(6.9094)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id={`_Linear15${random}`}
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="translate(193.042 213.314) scale(6.9094)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.29" stopColor="#fff" stopOpacity="0.6"></stop>
          <stop offset="0.78" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export const Bitcoin = ({ size }: { size: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 -0.5 34 34"
  >
    <path
      fill="#F7931A"
      fillRule="evenodd"
      d="M33.254 16.13c0 8.907-7.221 16.128-16.128 16.128-8.908 0-16.129-7.22-16.129-16.129C.997 7.221 8.218 0 17.125 0c8.908 0 16.129 7.221 16.129 16.13ZM21 10.136c2.244.77 3.885 1.924 3.563 4.07-.234 1.573-1.109 2.333-2.27 2.6 1.594.827 2.137 2.396 1.632 4.294-.959 2.73-3.238 2.96-6.269 2.39l-.736 2.935-1.777-.442.726-2.896c-.46-.114-.931-.236-1.416-.366l-.729 2.91-1.775-.441.735-2.942-3.579-.9.883-2.026s1.31.346 1.292.32c.503.124.727-.202.815-.42l1.994-7.967c.023-.376-.108-.85-.828-1.03.028-.019-1.29-.32-1.29-.32l.472-1.89 3.584.884.73-2.908 1.775.442-.714 2.85c.478.108.958.218 1.425.334l.708-2.832 1.777.441-.728 2.91Zm-4.251 4.851c1.21.321 3.844 1.02 4.303-.811.468-1.874-2.09-2.44-3.343-2.716-.142-.032-.268-.06-.37-.085l-.884 3.536c.084.02.183.047.294.076Zm-1.371 5.693c1.45.382 4.62 1.217 5.124-.8.517-2.064-2.557-2.75-4.057-3.086-.168-.037-.316-.07-.437-.1l-.976 3.896c.1.025.215.055.345.09Z"
      clipRule="evenodd"
    />
  </svg>
);
