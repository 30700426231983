import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import DepositAddressModal from "./DepositAddressModal";
import TransferViaWallet from "./TransferViaWallet";

export default function TransferButton() {
  return (
    <VStack w="100%" gap={2}>
      <TransferViaWallet />
      <HStack w="100%" justifyContent="space-around">
        <Divider w="45%" bg="white" />
        <Text>or</Text>
        <Divider w="45%" bg="white" />
      </HStack>
      <DepositAddressModal />
    </VStack>
  );
}
