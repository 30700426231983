import { useBTCWalletKit } from "../wallet-kits/btc-wallet-kit";
import { useOsmosisWalletKit } from "../wallet-kits/osmosis-wallet-kit";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BridgeDirection, SubmitRequire } from "../types";
import { getProxyService } from "src/services";
import { parseAmount } from "src/utils/format";
import { BTC_DECIMALS } from "src/utils/constants";

interface Fee {
  fee: bigint;
  decimals: number;
  symbol: string;
}

interface WidgetContextProps {
  osmAddr?: string;
  btcAddr?: string;
  direction: BridgeDirection;
  targetAddr?: string;
  ckbtcAddr: string;
  useConnectWalletForReceiver: boolean;
  retrievingCkbtcAddr: boolean;
  amount: string;
  submitRequest?: SubmitRequire;
  onRevert?: () => void;
  onAmountChange?: (amount: string) => void;
  onTargetAddrChange?: (addr: string) => void;
  onToggleConnectWalletForReceiver?: () => void;
}

const initialState: WidgetContextProps = {
  direction: BridgeDirection.Transfer,
  targetAddr: "",
  ckbtcAddr: "",
  useConnectWalletForReceiver: true,
  retrievingCkbtcAddr: false,
  amount: "",
};

const WidgetContext = createContext<WidgetContextProps>(initialState);

export function useWidgetContext() {
  return useContext(WidgetContext);
}

function getConnectedAddr(
  direction: BridgeDirection,
  btcAddr: string,
  osmAddr: string,
) {
  if (direction === BridgeDirection.Transfer) {
    return osmAddr;
  } else {
    return btcAddr;
  }
}

async function getCkBTCAddress(osmAddr: string) {
  console.log("### getCkBTCAddress", osmAddr);

  try {
    const proxyService = await getProxyService();
    const depositAddrResult = await proxyService.get_btc_mint_address(osmAddr);
    if ("Ok" in depositAddrResult) {
      console.log("### getCkBTCAddress Ok", osmAddr);
      return depositAddrResult.Ok;
    }
  } catch (error) {
    console.log("### getCkBTCAddress Err: Failed to get ckbtc address", error);
  }
  return "";
}

export function WidgetProvider(props: { children: ReactNode }) {
  const { children } = props;

  const [retrievingCkbtcAddr, setRetrievingCkbtcAddr] = useState(
    initialState.retrievingCkbtcAddr,
  );
  const [amount, setAmount] = useState(initialState.amount);
  const [targetAddr, setTargetAddr] = useState(initialState.targetAddr);
  const [direction, setDirection] = useState(initialState.direction);
  const [ckbtcAddr, setCkbtcAddr] = useState(initialState.ckbtcAddr);
  const [useConnectWalletForReceiver, setUseConnectWalletForReceiver] =
    useState(initialState.useConnectWalletForReceiver);

  const { balance: btcDenomBalance, address: osmAddr } = useOsmosisWalletKit();
  const { balance: btcBalance, address: btcAddr } = useBTCWalletKit();

  const balance =
    direction === BridgeDirection.Transfer ? btcBalance : btcDenomBalance;

  const _osmAddr =
    direction === BridgeDirection.Transfer ? targetAddr : osmAddr;
  useEffect(() => {
    if (_osmAddr) {
      setRetrievingCkbtcAddr(true);
      getCkBTCAddress(_osmAddr).then((addr) => {
        setCkbtcAddr(addr);
        setRetrievingCkbtcAddr(false);
      });
    } else {
      setCkbtcAddr("");
    }
  }, [_osmAddr]);

  const submitRequest = useMemo(() => {
    if (direction === BridgeDirection.Transfer) {
      if (!targetAddr) {
        return SubmitRequire.FILL_OSMOSIS_ADDRESS;
      }
    } else {
      if (!osmAddr) {
        return SubmitRequire.CONNECT_OSMOSIS_WALLET;
      }
      if (!targetAddr) {
        return SubmitRequire.FILL_BITCOIN_ADDRESS;
      }
    }
    if (!amount) {
      return SubmitRequire.INPUT_AMOUNT;
    }

    if (Number(amount) <= 0) {
      return SubmitRequire.INVALID_AMOUNT;
    }

    const parsedAmount = Number(parseAmount(amount, BTC_DECIMALS).toString());
    if (parsedAmount > Number(balance?.toString())) {
      return SubmitRequire.INSUFFICIENT_BALANCE;
    }

    return SubmitRequire.START_TRANSFER;
  }, [
    osmAddr,
    ckbtcAddr,
    direction,
    amount,
    targetAddr,
    useConnectWalletForReceiver,
    balance,
  ]);

  const connectedTargetAddr = getConnectedAddr(direction, btcAddr, osmAddr);
  useEffect(() => {
    if (useConnectWalletForReceiver) {
      setTargetAddr(connectedTargetAddr ?? "");
    } else if (!useConnectWalletForReceiver) {
      setTargetAddr("");
    }
  }, [connectedTargetAddr, useConnectWalletForReceiver, direction]);

  const contextValue = useMemo(() => {
    const value: WidgetContextProps = {
      osmAddr,
      btcAddr,
      amount,
      direction,
      ckbtcAddr,
      targetAddr,
      submitRequest,
      onAmountChange: setAmount,
      useConnectWalletForReceiver,
      retrievingCkbtcAddr,
      onRevert: () => {
        setDirection(
          direction === BridgeDirection.Transfer
            ? BridgeDirection.Redeem
            : BridgeDirection.Transfer,
        );
        setAmount("");
      },
      onTargetAddrChange: setTargetAddr,
      onToggleConnectWalletForReceiver: () =>
        setUseConnectWalletForReceiver(!useConnectWalletForReceiver),
    };
    return value;
  }, [
    osmAddr,
    btcAddr,
    ckbtcAddr,
    direction,
    amount,
    targetAddr,
    submitRequest,
    useConnectWalletForReceiver,
    retrievingCkbtcAddr,
  ]);

  return (
    <WidgetContext.Provider value={contextValue}>
      {children}
    </WidgetContext.Provider>
  );
}
