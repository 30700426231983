import { Text, Link, HStack, useToast, VStack } from "@chakra-ui/react";
import ServiceFactory from "@services/index";
import { Ticket, ChainID, TxStatus } from "@types";
import { useEffect, useState } from "react";
import { CHECK_TX_STATUS_INTERVAL } from "../utils/constants";
import BaseService from "@services/BaseService";
import posthog from "posthog-js";

export default function TicketTxStatus({
  service,
  ticket,
  onReverted,
  onCompleted,
  onGenerateTicket,
}: {
  service: BaseService | null;
  ticket: Ticket;
  onReverted?: () => void;
  onCompleted?: (finalized: boolean) => void;
  onGenerateTicket?: () => void;
}) {
  const [txStatus, setTxStatus] = useState<TxStatus>();

  const waitTxStatusBeforeGenerateTicket =
    ServiceFactory.waitTxStatusBeforeGenerateTicket(ticket);

  const toast = useToast();

  useEffect(() => {
    if (txStatus && txStatus !== "pending") {
      return;
    }
    function fetchTxStatus() {
      if (ticket && waitTxStatusBeforeGenerateTicket) {
        ServiceFactory.getTxStatus(ticket)
          .then(async (result) => {
            setTxStatus(result);
            if (result === "reverted") {
              onReverted && onReverted();
              posthog.capture("ticket tx fail", { ...ticket });
            } else if (result === "success") {
              if (
                service?.chain.chain_id === ticket.src_chain &&
                ticket.ticket_id
              ) {
                onGenerateTicket && onGenerateTicket();
                const result = await service.generateTicket(ticket);
                onCompleted && onCompleted(result.finalized);
                if (!result.finalized) {
                  throw new Error(
                    `${result.message}, please report to support, don't resubmit`,
                  );
                }
              }
            }
          })
          .catch((error) => {
            toast({
              description: error.message,
              status: "error",
              duration: 5000,
            });
          });
      }
    }
    fetchTxStatus();
    const tick = setInterval(fetchTxStatus, CHECK_TX_STATUS_INTERVAL);
    if (waitTxStatusBeforeGenerateTicket && txStatus === "pending") {
      window.onbeforeunload = function (e) {
        return "Please stay a moment until the transaction is completed";
      };
    }
    return () => {
      clearInterval(tick);
      window.onbeforeunload = null;
    };
  }, [txStatus, waitTxStatusBeforeGenerateTicket, service?.chain.chain_id]);

  if (waitTxStatusBeforeGenerateTicket) {
    return (
      <VStack mb={4} gap={1}>
        <HStack>
          <Link href={ServiceFactory.getTicketIdLink(ticket)} target="_blank">
            <Text color="blue.500" maxW={200} isTruncated>
              View on Explorer
            </Text>
          </Link>
        </HStack>
        {txStatus !== "success" && ticket.src_chain !== ChainID.Bitcoin && (
          <Text fontSize={14} color="red.400">
            {"Don't close browser for now"}
          </Text>
        )}
      </VStack>
    );
  }

  return null;
}
