import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Text,
  chakra,
  Box,
  Skeleton,
} from "@chakra-ui/react";
import { BridgeDirection } from "../types";
import { parseAmount } from "../utils/format";
import {
  BTC_DECIMALS,
  MIN_REDEEM_AMOUNT,
  MIN_TRANSFER_AMOUNT,
  OSMOSIS_CONTRACT_ADDRESS,
} from "../utils/constants";
import { Bitcoin, Osmosis } from "./icons";
import { useState } from "react";
import { getCosmRouteService, getProxyService } from "../services";
import { ArrowRight } from "lucide-react";
import { CHAIN_INFO } from "../wallet-kits/osmosis-wallet-kit/constants";
import { GasPrice } from "@cosmjs/stargate";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Decimal } from "@cosmjs/math";
import { useWidgetContext } from "src/context/WidgetContext";
import SubmitButton from "./SubmitButton";
import QRCode from "react-qr-code";
import { Copy } from "lucide-react";

const CopyIcon = chakra(Copy);

export default function Confirm() {
  const {
    osmAddr,
    btcAddr,
    direction,
    amount,
    ckbtcAddr,
    retrievingCkbtcAddr,
    onAmountChange,
  } = useWidgetContext();
  const [confirming, setConfirming] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fromAddr = direction === BridgeDirection.Transfer ? btcAddr : osmAddr;
  const toAddr = direction === BridgeDirection.Redeem ? btcAddr : osmAddr;

  const toast = useToast();

  const onConfirm = async () => {
    setConfirming(true);
    try {
      if (!toAddr) {
        throw new Error("Receiver address is required");
      }
      const proxyService = await getProxyService();
      if (direction === BridgeDirection.Transfer) {
        const txs = await fetch(
          `https://mempool.space/api/address/${ckbtcAddr}/txs`,
        ).then((res) => res.json());
        if (!txs.some((t: any) => t.status.confirmed === false)) {
          throw new Error("No deposit record found");
        }
        await proxyService.update_balance_after_finalization(toAddr);
      } else if (direction === BridgeDirection.Redeem) {
        if (!window.keplr) {
          throw new Error("Please install keplr extension");
        }
        if (!fromAddr) {
          throw new Error("Sender address is required");
        }
        const parsedAmount = Number(
          parseAmount(amount, BTC_DECIMALS).toString(),
        );
        const minAmount = Number(
          parseAmount(MIN_REDEEM_AMOUNT, BTC_DECIMALS).toString(),
        );
        if (parsedAmount <= minAmount) {
          throw new Error(`Amount must be at least ${MIN_REDEEM_AMOUNT}`);
        }

        await window.keplr.experimentalSuggestChain(CHAIN_INFO);
        await window.keplr.enable(CHAIN_INFO.chainId);
        let offlineSigner: any = window.keplr.getOfflineSigner(
          CHAIN_INFO.chainId,
        );
        const accounts = await offlineSigner.getAccounts();
        if (accounts.length === 0) {
          throw new Error("There is no available key");
        }
        const key = await window.keplr.getKey(CHAIN_INFO.chainId);
        const isLedger = key.isNanoLedger;
        if (isLedger) {
          offlineSigner = window.keplr.getOfflineSignerOnlyAmino(
            CHAIN_INFO.chainId,
          );
        }
        const client = await SigningCosmWasmClient.connectWithSigner(
          CHAIN_INFO.rpc,
          offlineSigner,
          {
            gasPrice: new GasPrice(Decimal.one(1), "uosmo"),
          },
        );
        const msg = {
          redeem_token: {
            token_id: "sICP-icrc-ckBTC",
            receiver: toAddr,
            amount: parsedAmount.toString(),
            target_chain: "sICP",
          },
        };

        const feeInfo = await client.queryContractSmart(
          OSMOSIS_CONTRACT_ADDRESS,
          {
            get_fee_info: {},
          },
        );

        const result = await client.execute(
          fromAddr,
          OSMOSIS_CONTRACT_ADDRESS,
          msg,
          "auto",
          "",
          [
            {
              denom: "uosmo",
              amount: `${feeInfo.fee_token_factor}`,
            },
          ],
        );
        const txHash = result.transactionHash;
        const cosmosRouteService = await getCosmRouteService();
        const redeemResult = await cosmosRouteService.redeem(txHash);
        if ("Err" in redeemResult) {
          throw new Error(redeemResult.Err);
        }
        onAmountChange && onAmountChange("");
      }
      toast({
        description:
          direction === BridgeDirection.Transfer
            ? "BTC is on the way to Osmosis, stay tuned"
            : "Redeeming",
        status: "success",
        duration: 5000,
      });
      setConfirming(false);
      onClose();
    } catch (error) {
      toast({
        description: (error as Error).message,
        status: "error",
        duration: 5000,
      });
    }
    setConfirming(false);
  };

  const logoSize = 60;
  const fromAddrComp =
    direction === BridgeDirection.Transfer ? (
      <Bitcoin size={logoSize} />
    ) : (
      <Osmosis size={logoSize} />
    );
  const toAddrComp =
    direction === BridgeDirection.Redeem ? (
      <Bitcoin size={logoSize} />
    ) : (
      <Osmosis size={logoSize} />
    );

  return (
    <>
      <SubmitButton onOpen={onOpen} />

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {direction === BridgeDirection.Transfer ? "Transfer" : "Redeem"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems="flex-start" pb={4}>
              <HStack w="100%" justifyContent="center">
                {fromAddrComp}
                <ArrowRight size={24} />
                {toAddrComp}
              </HStack>

              {direction === BridgeDirection.Redeem && (
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} color="gray.400">
                    FROM
                  </Text>

                  <Text
                    maxW={400}
                    fontFamily="monospace"
                    fontSize={18}
                    color="blue.300"
                  >
                    {fromAddr}
                  </Text>
                </VStack>
              )}

              {direction === BridgeDirection.Redeem && (
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} color="gray.400">
                    ckBTC Deposit Address
                  </Text>
                  <Text
                    maxW={400}
                    fontFamily="monospace"
                    fontSize={18}
                    color="blue.300"
                  >
                    {ckbtcAddr}
                  </Text>
                </VStack>
              )}

              {direction === BridgeDirection.Redeem && (
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} color="gray.400">
                    TO
                  </Text>
                  <Text
                    maxW={400}
                    fontFamily="monospace"
                    fontSize={18}
                    color="blue.300"
                  >
                    {toAddr}
                  </Text>
                </VStack>
              )}

              {direction === BridgeDirection.Redeem && (
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} color="gray.400">
                    AMOUNT
                  </Text>
                  <HStack>
                    <Text
                      maxW={400}
                      fontFamily="monospace"
                      fontSize={18}
                      color="blue.300"
                    >
                      {amount}
                    </Text>
                    <Bitcoin size={20} />
                  </HStack>
                </VStack>
              )}

              {direction === BridgeDirection.Transfer && (
                <VStack w="100%" gap={0} mt={6}>
                  {retrievingCkbtcAddr || !ckbtcAddr ? (
                    <VStack w="100%" alignItems="center">
                      <Skeleton height={168} width={168} />
                      <Skeleton height={6} width={280} />
                    </VStack>
                  ) : (
                    <VStack w="100%" alignItems="center">
                      <Box
                        bg="white"
                        p={2}
                        borderRadius={4}
                        height={168}
                        width={168}
                      >
                        {ckbtcAddr && (
                          <QRCode
                            value={ckbtcAddr}
                            size={152}
                            bgColor="white"
                          />
                        )}
                      </Box>
                      <HStack
                        cursor="pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(ckbtcAddr);
                          toast({
                            title: "Copied",
                            status: "success",
                            duration: 3000,
                          });
                        }}
                      >
                        <Text
                          color="blue.400"
                          maxW={window.innerWidth * 0.78}
                          textAlign="center"
                          fontFamily="monospace"
                        >
                          {ckbtcAddr || "Missing Osmosis address"}
                        </Text>
                        <CopyIcon color="gray.300" size={16} />
                      </HStack>
                    </VStack>
                  )}

                  <Text fontSize={14} color="gray.400" mt={2}>
                    Please deposit BTC to this address,{" "}
                    <Text display="inline" fontWeight={600}>
                      {MIN_TRANSFER_AMOUNT} BTC
                    </Text>{" "}
                    at least.
                  </Text>
                </VStack>
              )}

              <Button
                colorScheme="blue"
                w="100%"
                borderRadius={2}
                onClick={onConfirm}
                mt={4}
                isLoading={retrievingCkbtcAddr || confirming}
                loadingText={
                  retrievingCkbtcAddr
                    ? "Generating Deposit Address"
                    : direction === BridgeDirection.Transfer
                      ? "Checking"
                      : "Redeeming"
                }
              >
                {direction === BridgeDirection.Transfer
                  ? "I have deposited"
                  : "Redeem"}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
