import { FeeType } from "@types";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";
import { FETCH_BTC_FEE_RATE_INTERVAL } from "../utils/constants";
import BitcoinService from "@services/BitcoinService";

interface FeeRate {
  [FeeType.Fastest]: number;
  [FeeType.HalfHour]: number;
  [FeeType.Hour]: number;
  selected: FeeType;
}

const feeRateAtom = atomWithStorage<FeeRate>("feeRate", {
  [FeeType.Fastest]: 10,
  [FeeType.HalfHour]: 10,
  [FeeType.Hour]: 10,
  selected: FeeType.HalfHour,
});

export default function useFeeRate() {
  const [feeRate, setFeeRate] = useAtom(feeRateAtom);

  useEffect(() => {
    async function fetchFeeRate() {
      try {
        const gasFee = await BitcoinService.fetchFeeRate();
        setFeeRate({
          ...gasFee,
          selected: feeRate.selected,
        });
      } catch (error) {}
    }
    fetchFeeRate();
    const tick = setInterval(fetchFeeRate, FETCH_BTC_FEE_RATE_INTERVAL);
    return () => {
      clearInterval(tick);
    };
  }, []);

  return { feeRate, setFeeRate };
}
