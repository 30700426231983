import {
  chakra,
  HStack,
  Link,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { isEvmChain } from "../utils/chains";
import ServiceFactory from "@services/index";
import { ArrowUpRight } from "lucide-react";
import { ChainName, Token } from "@types";
import BaseService from "@services/BaseService";
import { useState } from "react";
import EvmRouteService from "@services/EvmRouteService";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

const ArrowUpRightIcon = chakra(ArrowUpRight);

export default function TokenOnL2({
  chainName,
  token,
  service,
  isLoading,
  isBitfinity,
}: {
  chainName?: ChainName;
  token?: Token;
  service?: BaseService | null;
  isLoading?: boolean;
  isBitfinity?: boolean;
}) {
  const [addingToken, setAddingToken] = useState(false);
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const { chainId: evmChainId, switchChain } = useEVMWalletKit();
  const toast = useToast();

  const onAddToken = async () => {
    try {
      if (addingToken) {
        return;
      }
      setAddingToken(true);
      if (!token || !service) {
        throw new Error("Invalid target token or service");
      }
      const evmChain = service.chain.evm_chain;
      if (evmChain && evmChain.id !== evmChainId) {
        switchChain({
          chainId: evmChain.id,
          addEthereumChainParameter: {
            chainName: evmChain.name,
            nativeCurrency: evmChain.nativeCurrency,
            rpcUrls: evmChain.rpcUrls.default.http,
            blockExplorerUrls: [evmChain.blockExplorers!.default.url],
          },
        });
      } else {
        await (service as EvmRouteService)?.addToken(token);
      }
      setAddingToken(false);
    } catch (error) {
      setAddingToken(false);
      toast({
        description: (error as Error).message,
        status: "error",
        duration: 5000,
      });
    }
  };
  return (
    <VStack w="100%" alignItems="flex-start" gap={1}>
      <HStack w="100%" alignItems="center" justifyContent="space-between">
        <Text fontWeight={600}>Token on {chainName}</Text>
        {isEvmChain(chainName) && token && !isLoading && (
          <Text
            fontSize={14}
            color="blue.300"
            cursor="pointer"
            onClick={onAddToken}
          >
            Add to MetaMask
          </Text>
        )}
      </HStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        bg={isBitfinity ? "bg.module" : boxWrapperBg}
        p={2}
        borderRadius={4}
      >
        {isLoading ? (
          <Skeleton w={300} h={23} isLoaded={false} />
        ) : token ? (
          <Link href={ServiceFactory.getTokenLink(token)} target="_blank">
            <HStack gap={0} role="group">
              <Text color="blue.400" fontFamily="monospace">
                {token?.id}
              </Text>
              <ArrowUpRightIcon color="blue.400" strokeWidth={1} size={18} />
            </HStack>
          </Link>
        ) : null}
      </VStack>
    </VStack>
  );
}
