import { useMemo } from "react";
import { useBurnContext } from "../context/BurnContext";
import { useHubContext } from "../context/OmnityHubContext";
import ServiceFactory from "../services";
import { ServiceType } from "@types";

export default function useBurnService() {
  const { chains } = useHubContext();
  const { burnChain } = useBurnContext();

  const result = useMemo(() => {
    const _burnChains = chains.filter(
      (c) => c.chain_name === burnChain && c.service_type === ServiceType.Route,
    );
    const _burnServices = _burnChains.map((c) =>
      ServiceFactory.createService(c),
    );

    let _burnService = null;

    if (_burnServices.length > 0 && _burnServices[0]) {
      _burnService = _burnServices[0];
    }

    return {
      burnService: _burnService,
      burnServices: _burnServices,
    };
  }, [burnChain, chains.length]);

  return result;
}
