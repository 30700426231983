import { ChainName, SubmitRequire, TabAction } from "@types";
import BTCConnectButton from "./BTC";
import ICPConnectButton from "./ICP";
import EVMConnectButton from "./EVM";
import SOLConnectButton from "./SOL";
import ConfirmTransfer from "./ConfirmTransfer";
import { Button } from "@chakra-ui/react";
import { useChainContext } from "../../context/ChainContext";
import { isEvmChain } from "../../utils/chains";
import BaseService from "@services/BaseService";
import { useHubContext } from "../../context/OmnityHubContext";
import ConfirmBurn from "./ConfirmBurn";
import ConfirmAddRunes from "./ConfirmAddRunes";
import ConfirmMint from "./ConfirmMint";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

export default function BaseSubmit({
  chainName,
  submitRequest,
  service,
}: {
  chainName?: ChainName;
  submitRequest?: SubmitRequire;
  service?: BaseService | null;
}) {
  const { onShowChainsModal } = useChainContext();
  const { switchChain } = useEVMWalletKit();
  const { tabAction } = useHubContext();

  if (!submitRequest) return null;

  if (SubmitRequire.Under_Maintenance === submitRequest) {
    return (
      <Button
        colorScheme="gray"
        w="98%"
        fontSize={24}
        py={8}
        borderRadius={8}
        disabled
        cursor="not-allowed"
      >
        {submitRequest}
      </Button>
    );
  }

  if (
    [
      SubmitRequire.Select_Source_Chain,
      SubmitRequire.Select_Target_Chain,
    ].includes(submitRequest)
  ) {
    return (
      <Button
        colorScheme="blue"
        w="98%"
        fontSize={24}
        py={8}
        borderRadius={8}
        onClick={() =>
          onShowChainsModal(SubmitRequire.Select_Source_Chain === submitRequest)
        }
      >
        Select{" "}
        {SubmitRequire.Select_Source_Chain === submitRequest
          ? "Source"
          : "Target"}{" "}
        Chain
      </Button>
    );
  }

  if (submitRequest === SubmitRequire.Connect_Source_Chain_Wallet) {
    return <ChainSubmitButton chainName={chainName} />;
  }

  if (SubmitRequire.Wrong_Network === submitRequest) {
    return (
      <Button
        colorScheme="blue"
        w="98%"
        fontSize={24}
        py={8}
        borderRadius={8}
        onClick={() => {
          if (service) {
            const evmChain = service.chain.evm_chain;
            if (evmChain) {
              switchChain({
                chainId: evmChain.id,
                addEthereumChainParameter: {
                  chainName: evmChain.name,
                  nativeCurrency: evmChain.nativeCurrency,
                  rpcUrls: evmChain.rpcUrls.default.http,
                  blockExplorerUrls: [evmChain.blockExplorers!.default.url],
                },
              });
            }
          }
        }}
      >
        {submitRequest}
      </Button>
    );
  }

  if (
    [
      SubmitRequire.Select_Token,
      SubmitRequire.Enter_Amount,
      SubmitRequire.Insufficient_Balance,
      SubmitRequire.Invalid_Amount,
      SubmitRequire.Invalid_Chain,
      SubmitRequire.Invalid_Address,
      SubmitRequire.Input_Receiving_Address,
      SubmitRequire.Source_Chain_Not_Active,
      SubmitRequire.Target_Chain_Not_Active,
      SubmitRequire.Select_Runes,
      SubmitRequire.Select_Chain,
      SubmitRequire.Input_Symbol,
      SubmitRequire.Select_Mint_Chain,
      SubmitRequire.Connect_Mint_Wallet,
      SubmitRequire.Chain_Not_Active,
      SubmitRequire.Invalid_Symbol,
    ].includes(submitRequest)
  ) {
    return (
      <Button
        colorScheme="gray"
        w="98%"
        fontSize={24}
        py={8}
        borderRadius={8}
        disabled
        cursor="not-allowed"
      >
        {submitRequest}
      </Button>
    );
  }

  if (submitRequest === SubmitRequire.Confirm) {
    switch (tabAction) {
      case TabAction.Burn:
        return <ConfirmBurn />;
      case TabAction.Transfer:
        return <ConfirmTransfer />;
      case TabAction.AddRunes:
        return <ConfirmAddRunes />;
      case TabAction.Mint:
        return <ConfirmMint />;
    }
  }

  return null;
}

export function ChainSubmitButton({
  chainName,
  forSubmit = true,
}: {
  chainName?: ChainName;
  forSubmit?: boolean;
}) {
  if (!chainName) {
    return null;
  }
  if (chainName === ChainName.Bitcoin) {
    return <BTCConnectButton forSubmit={forSubmit} />;
  }
  if (chainName === ChainName.ICP) {
    return <ICPConnectButton forSubmit={forSubmit} />;
  }
  if (chainName === ChainName.Solana) {
    return <SOLConnectButton forSubmit={forSubmit} />;
  }
  if (isEvmChain(chainName)) {
    return <EVMConnectButton forSubmit={forSubmit} />;
  }
  return null;
}
