import { Token } from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMintContext } from "./MintContext";
import useMintService from "../hooks/useMintService";
import { FETCH_TOKEN_INTERVAL } from "../utils/constants";

interface TokenContextProps {
  tokens: Token[];
  isLoading: boolean;
  updateTokens: () => Promise<void>;
}

const initialState: TokenContextProps = {
  tokens: [],
  isLoading: false,
  updateTokens: async () => {},
};

const TokenContext = createContext<TokenContextProps>(initialState);

export function useMintTokens() {
  return useContext(TokenContext);
}

export function MintTokenContextProvider(props: { children: ReactNode }) {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { executeServices } = useMintService();
  const { mintChain, mintAddr, token, onTokenChange, passedProps } =
    useMintContext();

  const fetchTokenList = useCallback(async () => {
    return await Promise.all(
      executeServices.map((s) =>
        s.fetchTokens(passedProps?.tokenIds, mintAddr),
      ),
    ).then((res) => {
      return res.flat();
    });
  }, [
    passedProps?.tokenIds?.length,
    mintChain,
    mintAddr,
    executeServices[0]?.chain.chain_id,
    executeServices[0]?.chain.token_list?.length,
  ]);

  useEffect(() => {
    if (!(tokens[0]?.chain_id === executeServices[0]?.chain.chain_id)) {
      setTokens([]);
      setIsLoading(true);
    }
    if (mintChain) {
      const tokenUpdate = () => {
        fetchTokenList().then((tokens) => {
          setTokens(tokens);
          if (token) {
            const _token = tokens.find((t) => t.token_id === token.token_id);
            onTokenChange(_token);
          } else if (passedProps?.tokenIds?.length && tokens[0]) {
            onTokenChange(tokens[0]);
          }
          setIsLoading(false);
        });
      };
      tokenUpdate();

      const tick = setInterval(tokenUpdate, FETCH_TOKEN_INTERVAL);
      return () => {
        tick && clearInterval(tick);
      };
    }
  }, [
    fetchTokenList,
    mintChain,
    passedProps?.tokenIds?.length,
    token?.token_id,
  ]);

  const updateTokens = useCallback(async () => {
    fetchTokenList().then((tokens) => {
      setTokens(tokens);
      if (token) {
        const _token = tokens.find((t) => t.token_id === token.token_id);
        if (_token) {
          onTokenChange(_token);
        }
      }
    });
  }, [fetchTokenList, token?.token_id, onTokenChange]);

  const contextValue = useMemo(() => {
    return {
      tokens,
      isLoading,
      updateTokens,
    };
  }, [tokens, isLoading, updateTokens]);

  return (
    <TokenContext.Provider value={contextValue}>
      {props.children}
    </TokenContext.Provider>
  );
}
