import { CopyIcon, LogOutIcon } from "lucide-react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  HStack,
  Text,
  VStack,
  useDisclosure,
  useToast,
  chakra,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import { useLaserEyes } from "@omnisat/lasereyes";
import { BTC_WALLETS } from "@wallet-kits/btc-wallet-kit/constants";

function formatBTCAddr(addr?: string) {
  if (!addr) {
    return "";
  }
  return addr.slice(0, 4) + "…" + addr.slice(-4);
}

const Copy = chakra(CopyIcon);
const Logout = chakra(LogOutIcon);

type BTCAddressProps = {
  isBitfinity?: boolean;
};
export default function BTCAddress({ isBitfinity }: BTCAddressProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { address, disconnect, provider } = useLaserEyes();

  const toast = useToast();
  const textColor = isBitfinity
    ? "text.white"
    : useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = isBitfinity
    ? "bg.darkMain"
    : useColorModeValue("gray.200", "gray.800");
  const boxWrapperHoverBg = isBitfinity
    ? "bg.darkHover"
    : useColorModeValue("gray.100", "gray.900");

  const wallet = BTC_WALLETS.find((w) => w.type === provider);
  const WalletIcon = wallet?.logo;

  return (
    <>
      <HStack cursor="pointer" alignItems="center" onClick={onOpen}>
        <Text color="#008cd5" fontFamily="monospace">
          {formatBTCAddr(address)}
        </Text>
        {WalletIcon && <WalletIcon size={24} />}
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <CloseButtonForModal />
          <ModalBody pb={6}>
            <VStack w="100%" gap={4}>
              <Tooltip label={address}>
                <Text fontSize={32} fontWeight={600} color={textColor}>
                  {formatBTCAddr(address)}
                </Text>
              </Tooltip>
              <HStack w="100%" gap={4}>
                <VStack
                  w="50%"
                  py={4}
                  borderRadius={8}
                  gap={0}
                  cursor="pointer"
                  color={textColor}
                  bg={boxWrapperBg}
                  _hover={{
                    bg: boxWrapperHoverBg,
                    transform: "scale(1.05)",
                  }}
                  transition="transform 0.2s, stroke-width 0.2s"
                  transitionTimingFunction="ease-in-out"
                  role="group"
                  onClick={() => {
                    navigator.clipboard.writeText(address);
                    toast({
                      title: "Copied to clipboard",
                      status: "success",
                      duration: 2000,
                    });
                  }}
                >
                  <Copy
                    strokeWidth={1}
                    color={textColor}
                    _groupHover={{ transform: "scale(1.1)", strokeWidth: 2 }}
                  />
                  <Text
                    fontSize={16}
                    fontWeight={500}
                    color={textColor}
                    _groupHover={{ transform: "scale(1.1)", fontWeight: 600 }}
                  >
                    Copy
                  </Text>
                </VStack>

                <VStack
                  w="50%"
                  py={4}
                  borderRadius={8}
                  gap={0}
                  cursor="pointer"
                  color={textColor}
                  bg={boxWrapperBg}
                  _hover={{
                    bg: boxWrapperHoverBg,
                    transform: "scale(1.05)",
                  }}
                  transition="transform 0.2s, stroke-width 0.2s"
                  transitionTimingFunction="ease-in-out"
                  role="group"
                  onClick={() => {
                    disconnect();
                    onClose();
                  }}
                >
                  <Logout
                    strokeWidth={1}
                    color={textColor}
                    _groupHover={{ transform: "scale(1.1)", strokeWidth: 2 }}
                  />
                  <Text
                    fontSize={16}
                    fontWeight={500}
                    color={textColor}
                    _groupHover={{ transform: "scale(1.1)", fontWeight: 600 }}
                  >
                    Disconnect
                  </Text>
                </VStack>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
