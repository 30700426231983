import { Button, useToast } from "@chakra-ui/react";
import { BridgeDirection, SubmitRequire } from "../types";
import { parseAmount } from "src/utils/format";
import { useWidgetContext } from "src/context/WidgetContext";
import {
  BTC_DECIMALS,
  MIN_REDEEM_AMOUNT,
  MIN_TRANSFER_AMOUNT,
} from "src/utils/constants";
import { useOsmosisWalletKit } from "src/wallet-kits/osmosis-wallet-kit";
import { useBTCWalletKit } from "src/wallet-kits/btc-wallet-kit";
import { getProxyService } from "src/services";

export default function SubmitButton({ onOpen }: { onOpen: () => void }) {
  const { balance: btcBalance, send } = useBTCWalletKit();
  const { balance: btcDenomBalance, onShowModal: onShowOSMModal } =
    useOsmosisWalletKit();
  const {
    amount,
    direction,
    submitRequest,
    ckbtcAddr,
    onAmountChange,
    targetAddr,
  } = useWidgetContext();
  const toast = useToast();

  const balance =
    direction === BridgeDirection.Transfer ? btcBalance : btcDenomBalance;
  let disabled = false;
  let onClick = () => {};
  let title: string | SubmitRequire | undefined = submitRequest;
  switch (submitRequest) {
    case SubmitRequire.CONNECT_OSMOSIS_WALLET:
      onClick = onShowOSMModal;
      break;
    case SubmitRequire.FILL_BITCOIN_ADDRESS:
    case SubmitRequire.FILL_OSMOSIS_ADDRESS:
    case SubmitRequire.INPUT_AMOUNT:
    case SubmitRequire.INVALID_AMOUNT:
    case SubmitRequire.INSUFFICIENT_BALANCE:
      disabled = true;
      break;
    case SubmitRequire.START_TRANSFER:
      {
        if (direction === BridgeDirection.Redeem) {
          title = "Redeem";
        }
        onClick = async () => {
          try {
            const parsedAmount = Number(
              parseAmount(amount, BTC_DECIMALS).toString(),
            );
            const minReadableAmount =
              direction === BridgeDirection.Transfer
                ? MIN_TRANSFER_AMOUNT
                : MIN_REDEEM_AMOUNT;
            const minAmount = Number(
              parseAmount(minReadableAmount, BTC_DECIMALS).toString(),
            );
            if (parsedAmount <= minAmount) {
              throw new Error(`Amount must be at least ${minReadableAmount}`);
            }
            if (parsedAmount > Number(balance?.toString())) {
              throw new Error("Insufficient balance");
            }
            if (!targetAddr) {
              throw new Error("Receiver address is required");
            }
            if (direction === BridgeDirection.Transfer) {
              await send(ckbtcAddr, parsedAmount);
              const proxyService = await getProxyService();
              await proxyService.update_balance_after_finalization(targetAddr);
              onAmountChange && onAmountChange("");
            } else {
              onOpen();
            }
          } catch (error) {
            toast({
              description: (error as Error).message,
              status: "error",
              duration: 5000,
            });
          }
        };
      }
      break;
    default:
      break;
  }
  return (
    <Button
      w="100%"
      size="lg"
      borderRadius={4}
      colorScheme="blue"
      opacity={disabled ? 0.7 : 1}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}
