export const fontStyles = {
  bodySmall: {
    fontSize: '11px',
    lineHeight: '11px'
  },
  body2: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  body: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  h6: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  h5: {
    fontSize: '18px',
    lineHeight: '24px'
  },
  h4: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'medium'
  },
  h3: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'semibold'
  },
  h2: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: 'bold'
  },
  h1: {
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 'bold'
  }
};
