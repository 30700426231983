import { Chain } from "viem";

export const bevm = {
  id: 11501,
  name: "BEVM Mainnet",
  nativeCurrency: { name: "BEVM Mainnet", symbol: "BTC", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://rpc-mainnet-1.bevm.io"] },
  },
  blockExplorers: {
    default: { name: "Bevmscan", url: "https://scan-mainnet.bevm.io" },
  },
} as const satisfies Chain;

export const bitlayer = {
  id: 200901,
  name: "Bitlayer Mainnet",
  nativeCurrency: { name: "Bitlayer Mainnet", symbol: "BTC", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://rpc.bitlayer-rpc.com"] },
  },
  blockExplorers: {
    default: { name: "Bitlayerscan", url: "https://www.btrscan.com" },
  },
} as const satisfies Chain;

export const bsquared = {
  id: 223,
  name: "B² Mainnet",
  nativeCurrency: { name: "BTC", symbol: "BTC", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://rpc.bsquared.network"] },
  },
  blockExplorers: {
    default: {
      name: "The B² Network Blockchain Explorer",
      url: "https://explorer.bsquared.network",
    },
  },
} as const satisfies Chain;

export const bitfinity = {
  id: 355110,
  name: "Bitfinity Mainnet",
  nativeCurrency: { name: "BFT", symbol: "BFT", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://mainnet.bitfinity.network"] },
  },
  blockExplorers: {
    default: {
      name: "Bitfinity Explorer",
      url: "https://explorer.mainnet.bitfinity.network",
    },
  },
} as const satisfies Chain;

export const ailayer = {
  id: 2649,
  name: "AILayer Mainnet",
  nativeCurrency: { name: "BTC", symbol: "BTC", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://mainnet-rpc.ailayer.xyz"] },
  },
  blockExplorers: {
    default: {
      name: "AILayer Explorer",
      url: "https://mainnet-explorer.ailayer.xyz",
    },
  },
} as const satisfies Chain;
