import { Button, VStack } from "@chakra-ui/react";
import { useWidgetContext } from "../context/WidgetContext";
import { useBTCWalletKit } from "@wallet-kits/btc-wallet-kit";
import SubmitButton from "./SubmitButton";

export default function TransferViaWallet() {
  const { btcAddr } = useWidgetContext();
  const { onShowModal } = useBTCWalletKit();

  const onClick = async () => {
    try {
      if (!btcAddr) {
        onShowModal();
        return;
      }
    } catch (error) {}
  };

  return (
    <VStack w="100%">
      {btcAddr ? (
        <SubmitButton onOpen={() => {}} />
      ) : (
        <Button
          w="100%"
          colorScheme="blue"
          size="lg"
          borderRadius={2}
          onClick={onClick}
        >
          Connect Bitcoin Wallet
        </Button>
      )}
    </VStack>
  );
}
