import trimEnd from 'lodash.trimend'

export function formatTokenName(name: string) {
  if (!name) {
    return ''
  }
  if (name.length <= 18) {
    return name
  }
  return `${name.slice(0, 17)}...${name.slice(-2)}`
}

export function formatUnits(value: bigint | undefined, decimals?: number) {
  if (typeof value === 'undefined' || value === null) {
    return '0'
  }
  const _decimals = decimals || 0
  let display = value.toString()

  const negative = display.startsWith('-')
  if (negative) display = display.slice(1)

  display = display.padStart(_decimals, '0')

  const [integer, fraction] = [
    display.slice(0, display.length - _decimals),
    display.slice(display.length - _decimals),
  ]
  const _fraction = fraction.replace(/(0+)$/, '')
  return `${negative ? '-' : ''}${integer || '0'}${
    _fraction ? `.${_fraction}` : ''
  }`
}

export function parseAmount(amount: string, decimals: number) {
  // eslint-disable-next-line prefer-const
  let [integer, fraction] = amount.split('.')
  if (fraction && fraction.length > decimals) {
    fraction = fraction.substring(0, decimals)
  }
  const display = integer + (fraction ? fraction : '').padEnd(decimals, '0')
  return BigInt(display)
}

export function formatHash(hash: string) {
  if (!hash) {
    return ''
  }

  return `${hash.slice(0, 6)}...${hash.slice(-4)}`
}

export function readableNumber(
  num: number | undefined | null | string,
  digits = 3
) {
  if (num === undefined || num === null) {
    return ''
  }

  const formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })

  const re = formatCurrency.format(Number(num))
  if (re.includes('.')) {
    const trimmed = trimEnd(re, '0')
    if (trimmed.endsWith('.')) {
      return trimmed.slice(0, -1)
    }
    return trimmed
  }

  return re
}
