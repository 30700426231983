import useBurnService from "@hooks/useBurnService";
import { useBurnContext } from "../../context/BurnContext";
import BaseSubmit from "./BaseSubmit";

export default function SubmitBurn() {
  const { burnChain, submitRequest } = useBurnContext();
  const { burnService } = useBurnService();

  return (
    <BaseSubmit
      chainName={burnChain}
      submitRequest={submitRequest}
      service={burnService}
    />
  );
}
