import { Button, ButtonGroup } from "@chakra-ui/react";
import { TicketInteractStep, TicketAction } from "../../types";

export default function TxSubmitButton({
  needRetry,
  onConfirm,
  onClose,
  ticketType,
  step,
}: {
  needRetry: boolean;
  onConfirm: () => void;
  onClose: () => void;
  ticketType: TicketAction;
  step: TicketInteractStep;
}) {
  if (needRetry) {
    return (
      <ButtonGroup w="98%" spacing="6">
        <Button
          colorScheme="blue"
          cursor="pointer"
          variant="outline"
          onClick={onClose}
          flex={1}
        >
          Cancel
        </Button>
        <Button
          colorScheme="blue"
          cursor="pointer"
          onClick={onConfirm}
          flex={1}
        >
          Retry
        </Button>
      </ButtonGroup>
    );
  }

  if (step === TicketInteractStep.COMPLETED) {
    return (
      <Button w="98%" colorScheme="blue" cursor="pointer" onClick={onClose}>
        Done
      </Button>
    );
  }

  const isPendingTx = step >= TicketInteractStep.SEND_TX;

  return (
    <Button
      colorScheme="blue"
      cursor="pointer"
      w="100%"
      fontSize={18}
      py={6}
      isLoading={isPendingTx}
      loadingText={`${ticketType}ing...`}
      onClick={onConfirm}
    >
      {ticketType}
    </Button>
  );
}
