import { Button } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

export default function EVMConnectButton({
  forSubmit,
}: {
  forSubmit: boolean;
}) {
  const { address } = useEVMWalletKit();

  return (
    <ConnectButton.Custom>
      {({ openConnectModal }) => {
        return (
          <Button
            colorScheme="blue"
            w={forSubmit ? "98%" : "100%"}
            fontSize={forSubmit ? 24 : 18}
            py={forSubmit ? 8 : 6}
            borderRadius={8}
            onClick={openConnectModal}
            disabled={!!address}
          >
            Connect EVM Wallet
          </Button>
        );
      }}
    </ConnectButton.Custom>
  );
}
