import { HStack, Link, Text, chakra } from "@chakra-ui/react";
import { HelpCircle } from "lucide-react";
import Logo from "./Logo";

const HelpCircleIcon = chakra(HelpCircle);

export default function Footer({ isBitfinity }: { isBitfinity?: boolean }) {
  return (
    <HStack w="100%" mt={4} alignItems="center" justifyContent="space-between">
      <Link
        href="https://oc.app/community/o5uz6-dqaaa-aaaar-bhnia-cai/channel/209373796018851818071085429101874032721/?ref=g6b5s-jqaaa-aaaar-bfbjq-cai"
        target="_blank"
        _hover={{ color: "white", textDecoration: "none" }}
        _focus={{ color: "white", textDecoration: "none" }}
        _active={{ color: "white", textDecoration: "none" }}
      >
        <HStack gap={1}>
          <HelpCircleIcon
            color="gray.400"
            size={16}
            _hover={{ color: "white" }}
          />
          <Text color="gray.400" fontWeight={400}>
            Support
          </Text>
        </HStack>
      </Link>
      <HStack paddingY={2} paddingX={3} className="footer-gradient">
        <Text
          fontStyle="italic"
          color={isBitfinity ? "secondary.main" : "gray.400"}
        >
          powered by
        </Text>
        <Logo />
      </HStack>
    </HStack>
  );
}
