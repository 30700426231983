import { ReactNode, useMemo } from "react";
import {
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { UnsafeBurnerWalletAdapter } from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";
import { createBurnInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { createMemoInstruction } from "@solana/spl-memo";
import { SOLWalletTransferParams } from "./types";
import { API_KEY, RPC } from "./constants";

export function useSOLWalletKit() {
  const { publicKey, signTransaction, sendTransaction, signAllTransactions } =
    useWallet();
  const { connection } = useConnection();

  const transfer = async ({
    feeAccount,
    feeAmount,
    amount,
    token,
    tokenAccountAddress,
    memo,
    fromAddress,
  }: SOLWalletTransferParams) => {
    if (!publicKey) {
      throw new Error("Wallet not connected");
    }
    if (!signTransaction || !sendTransaction || !signAllTransactions) {
      throw new Error("Wallet does not support transaction signing");
    }

    if (fromAddress !== publicKey.toString()) {
      throw new Error("Wallet address does not match");
    }

    const balance = await connection.getBalance(publicKey);
    if (BigInt(balance) <= feeAmount) {
      throw new Error("Insufficient $SOL balance");
    }

    const tx = new Transaction();

    tx.add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(feeAccount),
        lamports: feeAmount,
      }),
    );

    if (tokenAccountAddress) {
      tx.add(
        createBurnInstruction(
          new PublicKey(tokenAccountAddress),
          new PublicKey(token.id),
          publicKey,
          amount,
          [],
          TOKEN_PROGRAM_ID,
        ),
      );
    }

    if (memo) {
      tx.add(createMemoInstruction(memo));
    }

    const signature = await sendTransaction(tx, connection);
    return signature;
  };

  return {
    address: publicKey?.toString(),
    transfer,
  };
}

export function SOLWalletProvider({ children }: { children: ReactNode }) {
  const network = WalletAdapterNetwork.Mainnet;

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/anza-xyz/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new UnsafeBurnerWalletAdapter(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network],
  );

  return (
    <ConnectionProvider
      endpoint={RPC}
      config={{
        httpHeaders: {
          "api-key": API_KEY,
        },
      }}
    >
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
