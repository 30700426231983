import {
  Addresses,
  Chain,
  ChainID,
  ChainName,
  Rune,
  ServiceType,
} from "@types";
import { CHAIN_ID_NAME_MAP } from "./constants";

export function getChainAddr(addresses: Addresses, chain?: ChainName) {
  const { evmAddr, btcAddr, icpAddr, solAddr } = addresses;
  if (isEvmChain(chain)) {
    return evmAddr;
  } else if (chain === ChainName.Bitcoin) {
    return btcAddr;
  } else if (chain === ChainName.ICP) {
    return icpAddr;
  } else if (chain === ChainName.Solana) {
    return solAddr;
  }
  return undefined;
}

export function isEvmChain(nameOrId?: ChainName | ChainID) {
  const chain = getChainBy(nameOrId);
  return chain && !!chain?.evmChain;
}

export function getChainBy(nameOrId?: ChainName | ChainID) {
  if (!nameOrId) {
    return null;
  }
  let _chain = CHAIN_ID_NAME_MAP[nameOrId as ChainID];
  if (!_chain) {
    const _chainId = getChainIdFromName(nameOrId as ChainName)[0];
    if (_chainId) {
      _chain = CHAIN_ID_NAME_MAP[_chainId];
    }
  }
  return _chain;
}

export function getChainIdFromName(name?: ChainName): ChainID[] {
  if (!name) {
    return [];
  }
  const result: ChainID[] = [];
  for (const chainId in CHAIN_ID_NAME_MAP) {
    const c = CHAIN_ID_NAME_MAP[chainId as ChainID];
    if (c.chainName.toLowerCase() === name.toLowerCase()) {
      result.push(chainId as ChainID);
    }
  }
  return result;
}

export function getServiceTypeFromChainId(chainId: ChainID) {
  return CHAIN_ID_NAME_MAP[chainId]?.serviceType ?? ServiceType.Route;
}

export function getAvailableChainName(
  chainName: ChainName,
  chains: Chain[],
): ChainName[] {
  const sourceChains = chains.filter((c) => {
    return c.chain_name === chainName;
  });
  const targetChains = chains.filter((c) => {
    return sourceChains.some((sc) => {
      return sc.counterparties.includes(c.chain_id);
    });
  });
  return [...new Set(targetChains.map((c) => c.chain_name))];
}

export function getTokenByRune(chains: Chain[], rune?: Rune) {
  if (rune) {
    const bitcoinChain = chains.find(
      (chain) => chain.chain_id === ChainID.Bitcoin,
    );
    if (bitcoinChain) {
      const tokenAdded = (bitcoinChain.token_list ?? []).find(
        (token) => token.id === rune.rune_id,
      );

      if (tokenAdded) {
        return tokenAdded;
      }
    }
  }
  return undefined;
}
