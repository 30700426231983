/* eslint-disable @typescript-eslint/no-explicit-any */
import { request } from "graphql-request";

const HOST =
  "https://omnity-hasura-graphql-engine-2252klcbva-uc.a.run.app/v1/graphql";

export const fetchGraph = async (query: string, variables: any) => {
  try {
    const data = await request({
      url: HOST,
      document: query,
      variables,
      requestHeaders: {
        "x-hasura-admin-secret": "C26sQVLsq3Azd7CoHfv",
      },
    });
    return data as any;
  } catch (error) {
    return null;
  }
};
