import { Button } from "@chakra-ui/react";
import { BridgeDirection } from "../types";
import { useBTCWalletKit } from "src/wallet-kits/btc-wallet-kit";
import { useOsmosisWalletKit } from "src/wallet-kits/osmosis-wallet-kit";
import { useWidgetContext } from "src/context/WidgetContext";

export default function ConnectTargetWallet() {
  const { useConnectWalletForReceiver, targetAddr, osmAddr, direction } =
    useWidgetContext();
  if (!useConnectWalletForReceiver || targetAddr) {
    return null;
  }
  const { onShowModal: onShowBTCModal } = useBTCWalletKit();
  const { onShowModal: onShowOSMModal } = useOsmosisWalletKit();
  const onConnect = () => {
    if (direction === BridgeDirection.Transfer) {
      onShowOSMModal();
    } else {
      onShowBTCModal();
    }
  };
  return (
    <Button
      size="lg"
      colorScheme="blue"
      w="100%"
      borderRadius={2}
      onClick={onConnect}
    >
      {`Connect ${direction === BridgeDirection.Transfer ? "Osmosis" : "Bitcoin"} Wallet`}
    </Button>
  );
}
