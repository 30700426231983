import { Circle } from "@chakra-ui/react";
import { ChainID, ChainName } from "../types";
import { getChainBy } from "../utils/chains";

export default function ChainLogo({
  chain,
  size,
}: {
  chain?: ChainName | ChainID;
  size: number;
}) {
  if (!chain) {
    return null;
  }
  let logo = null;
  let _chain = getChainBy(chain);
  if (_chain) {
    const Icon = _chain.icon;
    logo = <Icon size={size} />;
  }

  return (
    <Circle size={`${size}px`} overflow="hidden">
      {logo}
    </Circle>
  );
}
