import useTransferService from "@hooks/useTransferService";
import { useTransferContext } from "../../context/TransferContext";
import BaseSubmit from "./BaseSubmit";

export default function SubmitTransfer() {
  const { sourceChain, submitRequest } = useTransferContext();
  const { sourceService } = useTransferService();

  return (
    <BaseSubmit
      chainName={sourceChain}
      submitRequest={submitRequest}
      service={sourceService}
    />
  );
}
