import { Ticket } from "../types";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";

const ticketsAtom = atomWithStorage<Ticket[]>("omnity.tickets", []);

export default function useTickets() {
  const [tickets, setTickets] = useAtom(ticketsAtom);

  useEffect(() => {
    if (tickets.length > 0) {
      const nonFinalizedTickets = tickets.filter((t) => !t.finalized);
      setTickets(nonFinalizedTickets);
    }
  }, [tickets.length]);

  return {
    tickets,
    addTicket: (ticket: Ticket) => {
      setTickets((prev) => [ticket, ...prev]);
    },
    removeTicket: (ticket: Ticket) => {
      setTickets((prev) => prev.filter((t) => t !== ticket));
    },
    updateTicket: (ticket: Ticket) => {
      setTickets((prev) =>
        prev.map((t) => (t.ticket_id === ticket.ticket_id ? ticket : t)),
      );
    },
  };
}
