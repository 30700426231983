import { Button } from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

export default function SOLConnectButton({
  forSubmit,
}: {
  forSubmit: boolean;
}) {
  const { connected, connecting } = useWallet();
  const { setVisible } = useWalletModal();

  return (
    <Button
      colorScheme="blue"
      w={forSubmit ? "98%" : "100%"}
      fontSize={forSubmit ? 24 : 18}
      py={forSubmit ? 8 : 6}
      borderRadius={8}
      onClick={async () => {
        setVisible(true);
      }}
      disabled={!!connected}
      isLoading={connecting}
      loadingText="Connecting"
    >
      Connect Solana Wallet
    </Button>
  );
}
