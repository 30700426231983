import { useAddRunesContext } from "../../context/AddRunesContext";
import BaseSubmit from "./BaseSubmit";
import useAddRunesService from "@hooks/useAddRunesService";

export default function SubmitAddRunes() {
  const { executeChain, submitRequest } = useAddRunesContext();
  const { executeService } = useAddRunesService();

  return (
    <BaseSubmit
      chainName={executeChain}
      submitRequest={submitRequest}
      service={executeService}
    />
  );
}
