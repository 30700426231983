import { Stack, VStack, Text } from "@chakra-ui/react";
import { OmnityWidget, ChainName, TabAction, ChainID } from "@omnity/widget";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function ICP() {
  const [tab, setTab] = useState(TabAction.Transfer);
  const [searchParams] = useSearchParams();
  const sourceChain = searchParams.get("sourceChain") || ChainName.ICP;
  const targetChain = searchParams.get("targetChain") || ChainName.Bitfinity;
  const tokenId = searchParams.get("tokenId");

  useEffect(() => {
    document.title = `${tab} | Omnity Network`;
  }, [tab]);

  return (
    <Stack bgSize="contain" bgRepeat="no-repeat" bgPos="0px 200px">
      <VStack id="widget" py={4} gap={0}>
        <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
          Omnity Hub - ICP ICRC
        </Text>
        <OmnityWidget
          customs={ChainID.sICP}
          sourceChain={sourceChain as ChainName}
          targetChain={targetChain as ChainName}
          tab={tab}
          onTabChange={(action) => {
            setTab(action);
          }}
          tokenIds={tokenId ? [tokenId] : []}
          network="mainnet"
          reversible
          isPeerToPeer={false}
        />
      </VStack>
    </Stack>
  );
}
