export const BTC_DECIMALS = 8;

export const MIN_TRANSFER_AMOUNT = "0.0011";
export const MIN_REDEEM_AMOUNT = "0.001";

export const PROXY_CANISTER_ID = "ncfbq-kyaaa-aaaar-qah3a-cai";

export const COSM_ROUTE_CANISTER_ID = "ystyg-kaaaa-aaaar-qaieq-cai";

export const BTC_DENOM =
  "factory/osmo10c4y9csfs8q7mtvfg4p9gd8d0acx0hpc2mte9xqzthd7rd3348tsfhaesm/sICP-icrc-ckBTC";

export const OSMOSIS_CONTRACT_ADDRESS =
  "osmo10c4y9csfs8q7mtvfg4p9gd8d0acx0hpc2mte9xqzthd7rd3348tsfhaesm";

export const ICP_CUSTOMS_CANISTER_ID = "nlgkm-4qaaa-aaaar-qah2q-cai";

export const CKBTC_MINTER_CANISTER_ID = "mqygn-kiaaa-aaaar-qaadq-cai";
