export enum BridgeDirection {
  Transfer = "Transfer",
  Redeem = "Redeem",
}

export enum TicketType {
  Transfer = "Transfer",
  Redeem = "Redeem",
}

export interface Ticket {
  type?: TicketType;
  from: string;
  to: string;
  amount: string;
  hash?: string;
}

export enum SubmitRequire {
  CONNECT_OSMOSIS_WALLET = "Connect Osmosis Wallet",
  INPUT_AMOUNT = "Input Amount",
  INVALID_AMOUNT = "Invalid Amount",
  INSUFFICIENT_BALANCE = "Insufficient Balance",
  START_TRANSFER = "Transfer",
  FILL_OSMOSIS_ADDRESS = "Osmosis address is required",
  FILL_BITCOIN_ADDRESS = "Bitcoin address is required",
}

export enum IndexerTicketStatus {
  Generating = "Generating", // local status
  Unknown = "Unknown",
  WaitingForConfirmBySrc = "WaitingForConfirmBySrc",
  WaitingForConfirmByDest = "WaitingForConfirmByDest",
  Finalized = "Finalized",
  Pending = "Pending",
}

export enum TicketAction {
  Transfer = "Transfer",
  Burn = "Burn",
  Mint = "Mint",
  Redeem = "Redeem",
  RedeemIcpChainKeyAssets = "RedeemIcpChainKeyAssets",
}

export interface TicketItem {
  action: TicketAction;
  amount: string;
  dst_chain: string;
  memo: string;
  receiver: string;
  sender: string;
  src_chain: string;
  status: IndexerTicketStatus;
  ticket_id: string;
  ticket_seq: number;
  ticket_time: number;
  ticket_type: TicketAction;
  token: string;
  tx_hash?: string;
  intermediate_tx_hash?: string;
}
